import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import SuccessIcon from '../../../../../assets/icons/success_icon.png'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { FillSurveyCard } from './FillSurveyCard'
import { surveyItemDetails } from '../../../../../models/graphqlTypes'

interface Props {
  surveyDetails?: surveyItemDetails
}

export const FillSurveySuccess: React.FC<React.PropsWithChildren<Props>> = ({
  surveyDetails,
}) => {
  const { t } = useTranslation()

  const history = useHistory()

  const goBack = () => history.goBack()

  return (
    <FillSurveyCard onClose={goBack} surveyDetails={surveyDetails}>
      <Typography>{t('patients:surveys.thankYou')}</Typography>
      <Stack alignItems="center" m={3} gap={2}>
        <img src={SuccessIcon} alt="successIcon" />

        <Button onClick={goBack}>{t('common:close')}</Button>
      </Stack>
    </FillSurveyCard>
  )
}
