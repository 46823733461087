import React from 'react'
import { Button } from '@mui/material'
import { Question } from '../Questions/questions.types'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { useMe } from '../../../../../common/hooks/useMe'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { canEditQuestion } from '../../utils/question.util'
import {
  Edit as EditIcon,
  RemoveRedEye as ViewIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material'

interface Props {
  surveyQuestion: Question
  setQuestionToOpen: React.Dispatch<React.SetStateAction<Question | null>>
  setQuestionToEdit: React.Dispatch<React.SetStateAction<Question | null>>
  setShouldCopyQuestion: React.Dispatch<React.SetStateAction<boolean>>
}

export const QuestionActionsMenu: React.FC<React.PropsWithChildren<Props>> = ({
  surveyQuestion,
  setQuestionToOpen,
  setQuestionToEdit,
  setShouldCopyQuestion,
}) => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()

  const classes = useStylesForSurveyActionButtons()

  return (
    <>
      {canEditQuestion(surveyQuestion, me) && (
        <Button
          classes={{ textPrimary: classes.textPrimary }}
          variant="text"
          startIcon={<EditIcon />}
          onClick={() => setQuestionToEdit(surveyQuestion)}
          sx={{ justifyContent: 'flex-start' }}
        >
          {t('common:edit')}
        </Button>
      )}

      <Button
        classes={{ textPrimary: classes.textPrimary }}
        variant="text"
        startIcon={<ContentCopyIcon />}
        onClick={() => {
          setShouldCopyQuestion(true)
          setQuestionToEdit(surveyQuestion)
        }}
        sx={{ justifyContent: 'flex-start' }}
      >
        {t('common:duplicate')}
      </Button>

      <Button
        classes={{ textPrimary: classes.textPrimary }}
        variant="text"
        startIcon={<ViewIcon />}
        onClick={() => setQuestionToOpen(surveyQuestion)}
        sx={{ justifyContent: 'flex-start' }}
      >
        {t('common:view')}
      </Button>
    </>
  )
}
