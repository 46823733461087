import { useUserType } from '../../../hooks'
import { DashboardTab } from '../dashboard.types'

export interface UseDoctorDashboardPathsReturn {
  dashboardPath: string
  dashboardAppointmentsPath: string
  dashboardSurveysPath: string
}

export const useDashboardPaths = (): UseDoctorDashboardPathsReturn => {
  const userType = useUserType()
  const dashboardPath = `/${userType}/home`

  const dashboardAppointmentsPath = `${dashboardPath}/${DashboardTab.Appointments}`
  const dashboardSurveysPath = `${dashboardPath}/${DashboardTab.Surveys}`

  return {
    dashboardPath,
    dashboardAppointmentsPath,
    dashboardSurveysPath,
  }
}
