import { useUserType } from '../../../../../common/hooks'
import { SurveyResultTab } from './surveyResult.types'

export interface UseSurveyPathsReturn {
  surveyListPath: string
  surveyResultBaseUrl: string
  surveyResultBaseUrlWithId: string
  surveyResultQuestionsPath: string
  surveyResultFillOutsPath: string
  surveyResultAggregatedPath: string
  surveyResultPatientsPath: string
}

export const useSurveyPaths = (): UseSurveyPathsReturn => {
  const userType = useUserType()

  const surveyListPath = `/${userType?.toLowerCase()}/surveys`

  const surveyResultBaseUrl = `/${userType?.toLowerCase()}/survey-result`
  const surveyResultBaseUrlWithId = `${surveyResultBaseUrl}/:surveySchemaId`

  const surveyResultQuestionsPath = `${surveyResultBaseUrlWithId}/${SurveyResultTab.Questions}`
  const surveyResultFillOutsPath = `${surveyResultBaseUrlWithId}/${SurveyResultTab.FillOuts}`
  const surveyResultAggregatedPath = `${surveyResultBaseUrlWithId}/${SurveyResultTab.AggregatedResults}`
  const surveyResultPatientsPath = `${surveyResultBaseUrlWithId}/${SurveyResultTab.PatientResults}`

  return {
    surveyListPath,
    surveyResultBaseUrl,
    surveyResultBaseUrlWithId,
    surveyResultQuestionsPath,
    surveyResultFillOutsPath,
    surveyResultAggregatedPath,
    surveyResultPatientsPath,
  }
}
