import { Stack, Typography } from '@mui/material'
import React from 'react'

import SuccessIcon from '../../../../../../assets/icons/success_icon.png'
import { useTranslation } from '../../../../../../common/hooks'

export const SendSurveySuccess: React.FC = () => {
  const { t } = useTranslation('survey')
  return (
    <>
      <Stack alignItems="center" m={3} gap={2}>
        <img src={SuccessIcon} alt="successIcon" />
        <Typography>{t('sendSurvey.success')}</Typography>
      </Stack>
    </>
  )
}
