import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'

import {
  getSurveysForSchemaId,
  getSurveysForSchemaIdVariables,
} from '../../../../models/graphqlTypes'
import { GET_SURVEYS_FOR_SCHEMA_ID } from '../operations/surveyOperations'
import { DoctorPatientSurvey } from '../../Treatments/types/surveys.types'

type Options = QueryHookOptions<
  getSurveysForSchemaId,
  getSurveysForSchemaIdVariables
>

type ReturnData = {
  loading: boolean
  surveys: DoctorPatientSurvey[]
  totalSurveys: number
  hasMoreSurveys: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSurveys: (options: Options) => void
}

export const useSurveysBySchemaId = (options: Options = {}): ReturnData => {
  const {
    data: surveyData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveysForSchemaId, getSurveysForSchemaIdVariables>(
    GET_SURVEYS_FOR_SCHEMA_ID,
    {
      ...options,
    }
  )

  const totalSurveys = surveyData?.getSurveysForSchemaId.total || 0
  const surveys: DoctorPatientSurvey[] =
    surveyData?.getSurveysForSchemaId.surveys || []
  const hasMoreSurveys = surveys.length < totalSurveys

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSurveys: fetchMore,
    refetch,
    surveys,
    totalSurveys,
    hasMoreSurveys,
  }
}
