import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveySchemaById,
  getSurveySchemaByIdVariables,
} from '../../../../models/graphqlTypes'

import { GET_SURVEY_SCHEMA_BY_ID } from '../operations/surveyOperations'
import { SurveySchemaById } from '../types/surveyStore.types'
import { useMemo } from 'react'
import { useMe } from '../../../../common/hooks/useMe'

type Options = QueryHookOptions<
  getSurveySchemaById,
  getSurveySchemaByIdVariables
>

type Return = {
  surveySchema?: SurveySchemaById
  loading: boolean
  refetch: () => void
}
export const useSurveySchemaData = (options: Options = {}): Return => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const { data, loading, refetch } = useQuery<
    getSurveySchemaById,
    getSurveySchemaByIdVariables
  >(GET_SURVEY_SCHEMA_BY_ID, options)

  const surveySchema = data?.getSurveySchemaById
  const extendedSurveySchema = useMemo(() => {
    const isOwnSurvey = surveySchema?.user?.id === me?.id
    const isOwnerOrAdmin = isOwnSurvey || !!me?.isAdmin
    return surveySchema
      ? {
          ...surveySchema,
          isOwnSurvey,
          isOwnerOrAdmin,
        }
      : undefined
  }, [me?.id, me?.isAdmin, surveySchema])

  return {
    loading,
    surveySchema: extendedSurveySchema,
    refetch,
  }
}
