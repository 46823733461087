import { getDashboardAppointments } from '../../../models/graphqlTypes'

export enum DashboardTab {
  Appointments = 'appointments',
  Surveys = 'surveys',
}

export enum AppointmentTab {
  Today = 'today',
  Tomorrow = 'tomorrow',
  SevenDays = '7-days',
  ThirtyDays = '30-days',
  NinetyDays = '90-days',
}

export enum SurveyTab {
  Received = 'received',
  Sent = 'sent',
}

export type DashboardAppointment =
  getDashboardAppointments['getDashboardAppointments']['appointments'][number]
