import React, { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Popover,
  Stack,
  Tab,
  Tabs,
} from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  SurveySchemaStatusEnum,
  UserType,
} from '../../../../../models/graphqlTypes'
import { SurveyActionsMenu } from '../SurveyActions/SurveyActionsMenu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useFormContext } from 'react-hook-form'
import { ViewSurveyDetailsCore } from '../ViewSurvey/ViewSurveyDetailsCore'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { SurveyQuestionNumber } from '../SurveyDetails/SurveyQuestionNumber'
import { SurveyFillTime } from '../SurveyDetails/SurveyFillTime'
import { ViewSurveyTitle } from '../ViewSurvey/ViewSurveyTitle'
import { Link, useLocation } from 'react-router-dom'
import { SurveyResultTab } from './surveyResult.types'
import last from 'lodash/last'
import { FillOutStateFilter } from './FillOutStateFilter'
import { SurveyResultDateIntervalFilter } from './SurveyResultDateIntervalFilter'
import { useUserType } from '../../../../../common/hooks'

interface Props {
  surveySchema: SurveySchemaWithoutUser
}

export const SurveyResultHeader: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
}) => {
  const { t } = useTranslation()
  const userType = useUserType()
  const isDoctor = userType === UserType.Doctor
  const [actionsMenuOpenAnchor, setActionsMenuAnchor] =
    useState<HTMLButtonElement | null>(null)

  const { pathname } = useLocation()
  const currentTab = last(pathname.split('/'))

  const numberOfQuestionsInSurvey = surveySchema.surveySchemaQuestions.length

  const { reset, watch } = useFormContext()

  useEffect(() => {
    reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [reset, surveySchema])

  const status = watch('status')

  return (
    <>
      <Accordion defaultExpanded sx={{ overflow: 'hidden', borderRadius: 2 }}>
        <AccordionSummary sx={{ padding: 0, paddingLeft: 2, height: '32px' }}>
          <ViewSurveyTitle surveySchema={surveySchema} status={status} />
          <Box mx={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setActionsMenuAnchor(e.currentTarget)
              }}
              sx={{ marginTop: 1 }}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <ViewSurveyDetailsCore surveySchema={surveySchema} />
          <hr />
          <Stack direction={'row'} gap={{ xs: 2, sm: 5 }} sx={{ px: 3, pt: 1 }}>
            <SurveyQuestionNumber
              numberOfQuestions={numberOfQuestionsInSurvey}
            />
            <SurveyFillTime numberOfQuestions={numberOfQuestionsInSurvey} />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Stack direction={'row'} pt={1} alignItems={'center'}>
        <Tabs value={currentTab}>
          <Tab
            label={t('survey:result.tabs.questions')}
            value={SurveyResultTab.Questions}
            to={SurveyResultTab.Questions}
            component={Link}
          />
          {isDoctor && (
            <Tab
              label={t('survey:result.tabs.fillOutState')}
              value={SurveyResultTab.FillOuts}
              to={SurveyResultTab.FillOuts}
              component={Link}
            />
          )}
          {isDoctor && (
            <Tab
              label={t('survey:result.tabs.sumResults')}
              value={SurveyResultTab.AggregatedResults}
              to={SurveyResultTab.AggregatedResults}
              component={Link}
            />
          )}
          {isDoctor && (
            <Tab
              label={t('survey:result.tabs.patientResults')}
              value={SurveyResultTab.PatientResults}
              to={SurveyResultTab.PatientResults}
              component={Link}
            />
          )}
        </Tabs>

        {[SurveyResultTab.FillOuts].includes(currentTab as SurveyResultTab) && (
          <FillOutStateFilter />
        )}
        {[
          SurveyResultTab.AggregatedResults,
          SurveyResultTab.PatientResults,
        ].includes(currentTab as SurveyResultTab) && (
          <SurveyResultDateIntervalFilter />
        )}
      </Stack>

      {actionsMenuOpenAnchor && (
        <Popover
          open={!!actionsMenuOpenAnchor}
          anchorEl={actionsMenuOpenAnchor}
          onClose={() => setActionsMenuAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box padding={1.5}>
            <SurveyActionsMenu surveySchema={surveySchema} />
          </Box>
        </Popover>
      )}
    </>
  )
}
