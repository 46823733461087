import React, { useCallback, useEffect } from 'react'
import { useTranslation } from '../../hooks'
import { SurveyFilterForDoctor } from '../../../models/graphqlTypes'
import {
  BetmenList,
  BetmenListHeader,
  BetmenListHeaderCell,
  BetmenListBody,
  BetmenListItemCard,
  BetmenListItemCardCell,
  BetmenListFilter,
} from '../BetmenList'
import { useReceivedSurveysForDoctorOrAssistant } from './hooks/useReceivedSurveysForDoctorOrAssistant'
import { PAGE_SIZE } from '../../../config'
import { ChipGroup } from '../ChipGroup/ChipGroup'
import { SurveyStatus } from '../../../views/doctor/DoctorPatients/components/PatientSurveys/SurveyStatus'
import { SurveyActionButton } from '../../../views/doctor/DoctorPatients/components/PatientSurveys/SurveyActionButton'
import { Stack } from '@mui/material'
import { OpenFilterPanelButton } from '../FilterPanelButtons/OpenFilterPanelButton'

interface Props {
  filterInput: SurveyFilterForDoctor
  handleSearch: (searchTerm: string) => void
  isFilterPanelOpen: boolean
  handleFilterOpen: () => void
}

export const DoctorReceivedSurveysOnDashboard: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const { filterInput, handleSearch } = props
  const { t } = useTranslation()

  const {
    surveys,
    loading,
    hasMoreSurveys,
    fetchMoreSurveys,
    fetchingMore,
    refetch,
  } = useReceivedSurveysForDoctorOrAssistant({
    variables: {
      filterData: filterInput,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  const onSurveyEnter = useCallback(() => {
    const offset = surveys.length
    fetchMoreSurveys({
      variables: {
        filterData: filterInput,
        paginationInput: { limit: PAGE_SIZE, offset },
      },
    })
  }, [surveys.length, fetchMoreSurveys, filterInput])

  useEffect(() => {
    refetch()
  }, [filterInput, refetch])

  return (
    <BetmenList
      dataSource={surveys}
      fetchMore={onSurveyEnter}
      hasMoreItem={hasMoreSurveys}
      loading={loading}
      fetchMoreLoading={fetchingMore}
      notFoundMessage={t('home:appointments.noEvent')}
      gridColumnSizes={[3, 3, 3, 3]}
      filters={
        <Stack direction="row" alignItems="center" mb={1} spacing={1}>
          <BetmenListFilter
            searchTerm={filterInput.searchTerm ?? ''}
            searchPlaceholder={t('home:survey.searchPlaceholder')}
            handleSearch={(searchTerm) => handleSearch(searchTerm ?? '')}
          />
          <OpenFilterPanelButton {...props} />
        </Stack>
      }
      header={
        <BetmenListHeader>
          <BetmenListHeaderCell>
            {t('patients:survey.header.title')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.category')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.sender')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.status')}
          </BetmenListHeaderCell>
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {surveys.map((survey) => (
          <BetmenListItemCard key={survey.id}>
            <BetmenListItemCardCell>
              {survey.surveySchema.title}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <ChipGroup
                values={survey.surveySchema.categories.map((category) =>
                  t(`survey:survey.type.${category.toLowerCase()}`)
                )}
                limitTags={3}
              />
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              {t('common:formattedNameFull', {
                title: survey.doctor?.title,
                firstName: survey.doctor?.firstName,
                lastName: survey.doctor?.lastName,
              })}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <SurveyStatus survey={survey} />
              <SurveyActionButton survey={survey} />
            </BetmenListItemCardCell>
          </BetmenListItemCard>
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}
