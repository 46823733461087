import { QueryHookOptions, useQuery, NetworkStatus } from '@apollo/client'
import {
  getSurveySchemas,
  getSurveySchemasVariables,
} from '../../../../models/graphqlTypes'
import { GET_SURVEY_SCHEMAS } from '../operations/surveyOperations'
import { renameDuplicatedSurveyTitles } from '../utils/survey.util'
import { ExtendedSurveySchema, SurveySchema } from '../types/surveyStore.types'
import { useMemo } from 'react'
import { useMe } from '../../../../common/hooks/useMe'

type Options = QueryHookOptions<getSurveySchemas, getSurveySchemasVariables>

type ReturnData = {
  loading: boolean
  surveySchemas: ExtendedSurveySchema[]
  totalSchemas: number
  hasMoreSchemas: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSchemas: (options: Options) => void
}

export const useSurveySchemas = (options: Options = {}): ReturnData => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const {
    data: surveyData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveySchemas, getSurveySchemasVariables>(
    GET_SURVEY_SCHEMAS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )

  const totalSchemas = surveyData?.getSurveySchemas?.total || 0
  const surveySchemas: SurveySchema[] = renameDuplicatedSurveyTitles(
    surveyData?.getSurveySchemas.surveySchemas || []
  )
  const hasMoreSchemas = surveySchemas.length < totalSchemas

  const extendedSurveySchemas: ExtendedSurveySchema[] = useMemo(
    () =>
      surveySchemas.map((sc) => {
        const isOwnSurvey = sc.user?.id === me?.id
        const isOwnerOrAdmin = isOwnSurvey || !!me?.isAdmin
        return { ...sc, isOwnSurvey, isOwnerOrAdmin }
      }),
    [me?.id, me?.isAdmin, surveySchemas]
  )

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSchemas: fetchMore,
    refetch,
    surveySchemas: extendedSurveySchemas,
    totalSchemas,
    hasMoreSchemas,
  }
}
