import { useCallback } from 'react'
import { useAddSurveyToTreatment } from '../../../hooks/useAddSurveyToTreatment'
import { useUpdateSurveyFillableFromDate } from '../../../hooks/useUpdateSurveyFillableFromDate'
import { useStoreActions } from '../../../../../../store/store.hooks'
import {
  AddSurveyFormValues,
  AddSurveyModalParams,
} from '../../Survey/AddSurveyModal'
import { Survey } from '../../../types/treatments.types'
import { useTranslation } from '../../../../../../common/hooks'

interface UseAddSurveyToTreatmentProps {
  setSurvey: React.Dispatch<React.SetStateAction<Survey | null>>
  treatmentId: string
  patientUserId: string
  refetchTreatment?: () => void
  toggleAddSurveyModal: React.Dispatch<
    React.SetStateAction<AddSurveyModalParams>
  >
  clearFilterValue: () => void
}
interface UseAddSurveyToTreatmentReturn {
  onOpenSurvey: (survey: Survey) => void
  onEditSurvey: (survey: Survey) => void
  onAddSurveyModalClose: () => void
  onAddSaveSurveyModalSave: (
    formValues: AddSurveyFormValues,
    survey?: Survey
  ) => void
}

export const useSurveyOperations = ({
  setSurvey,
  treatmentId,
  patientUserId,
  refetchTreatment,
  toggleAddSurveyModal,
  clearFilterValue,
}: UseAddSurveyToTreatmentProps): UseAddSurveyToTreatmentReturn => {
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const onOpenSurvey = useCallback(
    (survey: Survey) => setSurvey(survey),
    [setSurvey]
  )

  const onEditSurvey = useCallback(
    (survey: Survey) => {
      clearFilterValue()
      toggleAddSurveyModal({
        isOpen: true,
        survey,
      })
    },
    [clearFilterValue, toggleAddSurveyModal]
  )

  const onAddSurveyModalClose = useCallback(
    () => toggleAddSurveyModal({ isOpen: false }),
    [toggleAddSurveyModal]
  )

  const [addSurveyToTreatment] = useAddSurveyToTreatment()
  const [updateSurveyFillableFromDate] = useUpdateSurveyFillableFromDate()

  const onSuccess = useCallback(
    (text: string) => {
      setToast({
        text,
        type: 'success',
      })
      refetchTreatment?.()
      onAddSurveyModalClose()
    },
    [onAddSurveyModalClose, refetchTreatment, setToast]
  )

  const onError = useCallback(
    (text: string) => {
      setToast({
        text,
        type: 'error',
      })

      onAddSurveyModalClose()
    },
    [onAddSurveyModalClose, setToast]
  )

  const onAddSaveSurveyModalSave = useCallback(
    (formValues: AddSurveyFormValues, survey?: Survey) => {
      if (
        !formValues.fillableFromDate ||
        !formValues.surveySchema?.id ||
        !treatmentId
      ) {
        return
      }

      if (survey) {
        return updateSurveyFillableFromDate({
          variables: {
            surveyId: survey?.id,
            fillableFromDate: formValues.fillableFromDate,
            fillableUntilDate: formValues.fillableUntilDate,
          },
          onCompleted: () =>
            onSuccess(t('notification:successModifySurveyDate')),
          onError: () => onError(t('notification:errorModifySurveyDate')),
        })
      }

      addSurveyToTreatment({
        variables: {
          treatmentId,
          surveySchemaId: formValues.surveySchema?.id,
          patientUserId,
          fillableFrom: formValues.fillableFromDate,
          fillableUntil: formValues.fillableUntilDate,
        },
        onCompleted: () => onSuccess(t('notification:successInsertSurvey')),
        onError: () => onError(t('notification:errorInsertSurvey')),
      })
    },
    [
      addSurveyToTreatment,
      onError,
      onSuccess,
      t,
      patientUserId,
      treatmentId,
      updateSurveyFillableFromDate,
    ]
  )

  return {
    onOpenSurvey,
    onEditSurvey,
    onAddSurveyModalClose,
    onAddSaveSurveyModalSave,
  }
}
