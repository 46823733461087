import ShareIcon from '@mui/icons-material/Share'
import { Button, ButtonProps } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { ShareSurveyModal } from '../EditSurvey/ShareSurveyModal'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

interface Props extends ButtonProps {
  surveySchema?: SurveySchemaWithoutUser
}

export const ShareSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  ...buttonProps
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const classes = useStylesForSurveyActionButtons()

  const { watch } = useFormContext()
  const status = watch('status')

  return (
    <>
      {!surveySchema?.isGlobal && status === SurveySchemaStatusEnum.Active && (
        <Button
          classes={{ textPrimary: classes.textPrimary }}
          variant="text"
          startIcon={<ShareIcon />}
          onClick={() => setIsShareModalOpen(true)}
          {...buttonProps}
        >
          {t('common:share')}
        </Button>
      )}

      {surveySchema && (
        <ShareSurveyModal
          isOpen={isShareModalOpen}
          setIsModalOpen={setIsShareModalOpen}
          surveySchema={surveySchema}
        />
      )}
    </>
  )
}
