import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  ResendSurveyForm,
  ResendSurveyStep,
} from './SendSurvey/sendSurvey.types'
import {
  DEFAULT_FILLOUT_TIME_IN_DAYS,
  SurveyDatePicker,
} from './SurveyDatePicker'
import dayjs from 'dayjs'
import { useNotifications } from '../../../../../common/providers'
import { resendSurveyVariables } from '../../../../../models/graphqlTypes'
import { SendSurveySuccess } from './SendSurvey/SendSurveySuccess'
import CloseIcon from '@mui/icons-material/Close'
import { useResendSurvey } from '../../hooks/useResendSurvey'
import { useTranslation } from '../../../../../common/hooks'

interface Props extends DialogProps {
  surveyId: string
  onClose: () => void
}

export const ResendSurveyModal: React.FC<Props> = ({
  onClose,
  surveyId,
  ...props
}) => {
  const { t } = useTranslation('survey')
  const { setErrorToast } = useNotifications()

  const [sendSurveyStep, setSendSurveyStep] = React.useState(
    ResendSurveyStep.SelectDate
  )

  const today = dayjs().startOf('day')
  const oneWeekLater = today.add(DEFAULT_FILLOUT_TIME_IN_DAYS, 'day')

  const formMethods = useForm<ResendSurveyForm>({
    defaultValues: {
      fillableUntilDate: oneWeekLater,
    },
  })

  const { handleSubmit } = formMethods

  const [resendSurvey] = useResendSurvey({
    onCompleted: () => {
      setSendSurveyStep(ResendSurveyStep.Success)
    },
    onError: (e) => setErrorToast(e),
  })

  const onSubmit = handleSubmit(async (formValues) => {
    const variables: resendSurveyVariables = {
      surveyId,
      fillableUntilDate: formValues.fillableUntilDate,
    }

    await resendSurvey({
      variables,
    })
  })

  return (
    <>
      <FormProvider {...formMethods}>
        <Dialog fullWidth {...props}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t(`sendSurvey.title`)}

            <IconButton
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {sendSurveyStep === ResendSurveyStep.SelectDate && (
              <Stack>
                <SurveyDatePicker fillableUntilName="fillableUntilDate" />
              </Stack>
            )}
            {sendSurveyStep === ResendSurveyStep.Success && (
              <SendSurveySuccess />
            )}
          </DialogContent>
          <DialogActions sx={{ pb: 2, px: 3 }}>
            {sendSurveyStep != ResendSurveyStep.Success && (
              <Button variant="outlined" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            )}

            {sendSurveyStep === ResendSurveyStep.SelectDate && (
              <Button onClick={onSubmit}>{t('common:send')}</Button>
            )}
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  )
}
