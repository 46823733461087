import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  unsubscribeFromSurveySchema,
  unsubscribeFromSurveySchemaVariables,
} from '../../../../models/graphqlTypes'
import { UNSUBSCRIBE_SURVEY_SCHEMA } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  unsubscribeFromSurveySchema,
  unsubscribeFromSurveySchemaVariables
>

type Return = MutationTuple<
  unsubscribeFromSurveySchema,
  unsubscribeFromSurveySchemaVariables
>

export const useSurveySchemaUnsubscribe = (options: Options = {}): Return => {
  return useMutation<
    unsubscribeFromSurveySchema,
    unsubscribeFromSurveySchemaVariables
  >(UNSUBSCRIBE_SURVEY_SCHEMA, {
    refetchQueries: ['getTreatmentSchemas'],
    ...options,
  })
}
