import { gql } from '@apollo/client'
import {
  FRAGMENT_MULTILANGTEXT,
  FRAGMENT_PATIENT_CHORE,
  FRAGMENT_SURVEY_LIST_ITEM,
} from './fragments'

export const DASHBOARD_APPOINTMENTS = gql`
  query getDashboardAppointments(
    $startDate: String!
    $endDate: String!
    $paginationInput: PaginationInput
  ) {
    getDashboardAppointments(
      startDate: $startDate
      endDate: $endDate
      paginationInput: $paginationInput
    ) {
      appointments {
        id
        status
        isBetmenBooked
        eesztBookingId
        room {
          id
        }
        proposedDate
        info {
          id
          doctorTitle {
            ...multiLangText
          }
        }
        doctorProfile {
          title
          firstName
          lastName
        }
        treatment {
          id
          status
          title {
            ...multiLangText
          }
          patient {
            ...patientChore
          }
        }
      }
      total
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_PATIENT_CHORE}
`

export const RECEIVED_SURVEYS_FOR_DOCTOR = gql`
  query getReceivedSurveysForDoctor(
    $filterData: SurveyFilterForDoctor!
    $paginationInput: PaginationInput
  ) {
    getReceivedSurveysForDoctor(
      filterData: $filterData
      paginationInput: $paginationInput
    ) {
      total
      surveys {
        ...surveyListItem
      }
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`

export const SENT_SURVEYS_FOR_DOCTOR = gql`
  query getSentSurveysForDoctor(
    $filterData: SurveyFilterForDoctor!
    $paginationInput: PaginationInput
  ) {
    getSentSurveysForDoctor(
      filterData: $filterData
      paginationInput: $paginationInput
    ) {
      total
      surveys {
        ...surveyListItem
      }
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`

export const RECEIVED_SURVEYS_FOR_ASSISTANT = gql`
  query getReceivedSurveysForAssistant(
    $filterData: SurveyFilterForAssistant!
    $paginationInput: PaginationInput
  ) {
    getReceivedSurveysForAssistant(
      filterData: $filterData
      paginationInput: $paginationInput
    ) {
      total
      surveys {
        ...surveyListItem
      }
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`

export const SENT_SURVEYS_FOR_ASSISTANT = gql`
  query getSentSurveysForAssistant(
    $filterData: SurveyFilterForAssistant!
    $paginationInput: PaginationInput
  ) {
    getSentSurveysForAssistant(
      filterData: $filterData
      paginationInput: $paginationInput
    ) {
      total
      surveys {
        ...surveyListItem
      }
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`
