import { NetworkStatus } from '@apollo/client'
import {
  PaginationInput,
  SurveyFilterForAssistant,
  SurveyFilterForDoctor,
} from '../../../models/graphqlTypes'
import { DoctorPatientSurvey } from '../../../views/doctor/Treatments/types/surveys.types'

export const getSurveyListReturn = (
  result: {
    loading: boolean
    networkStatus: NetworkStatus
    fetchMore: (data: {
      variables: {
        filterData: SurveyFilterForDoctor | SurveyFilterForAssistant
        paginationInput?: PaginationInput | null
      }
    }) => void
    refetch: () => void
  },
  data?: { total: number; surveys: DoctorPatientSurvey[] }
) => {
  const { loading, networkStatus, fetchMore, refetch } = result
  const totalSurveys = data?.total ?? 0
  const surveys: DoctorPatientSurvey[] = data?.surveys ?? []
  const hasMoreSurveys = surveys.length < totalSurveys

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSurveys: fetchMore,
    refetch,
    surveys,
    totalSurveys,
    hasMoreSurveys,
  }
}
