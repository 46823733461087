import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useSurveySchemaStatusUpdate } from '../../hooks/useSurveySchemaStatusUpdate'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

interface Props extends ButtonProps {
  surveySchema: SurveySchemaWithoutUser
}

export const PublishSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const { setSuccessToast, setErrorToast, setCustomErrorToast } =
    useNotifications()
  const classes = useStylesForSurveyActionButtons()

  const { setValue, watch } = useFormContext()
  const status = watch('status') || surveySchema.status

  const [updateSurveyStatus] = useSurveySchemaStatusUpdate()

  const changeSurveyStatus = async (
    newStatus: SurveySchemaStatusEnum,
    successMessageKey: string
  ) =>
    await updateSurveyStatus({
      variables: {
        status: newStatus,
        surveySchemaId: surveySchema.id,
      },
      refetchQueries: ['getSurveySchemaById', 'getSurveySchemas'],
      onCompleted: () => {
        setValue('status', newStatus)
        setSuccessToast(successMessageKey)
      },
      onError: (e) => setErrorToast(e),
    })

  const onPublish = async () => {
    if (!surveySchema.surveySchemaQuestions.length) {
      return setCustomErrorToast(t('survey:noQuestionsError'))
    }

    return await changeSurveyStatus(
      SurveySchemaStatusEnum.Active,
      'successPublishSurvey'
    )
  }

  const onUnPublish = async () =>
    await changeSurveyStatus(
      SurveySchemaStatusEnum.InActive,
      'successUnPublishSurvey'
    )

  if (surveySchema.isOwnerOrAdmin) {
    return (
      <>
        {status === SurveySchemaStatusEnum.InActive && (
          <Button
            classes={{ textPrimary: classes.textPrimary }}
            onClick={onPublish}
            startIcon={<VisibilityIcon />}
            {...buttonProps}
            data-cy={'PublishSurveyButton'}
          >
            {t('survey:publish')}
          </Button>
        )}
        {status === SurveySchemaStatusEnum.Active && (
          <Button
            onClick={onUnPublish}
            color="error"
            startIcon={<VisibilityOffIcon />}
            {...buttonProps}
          >
            {t('survey:unPublish')}
          </Button>
        )}
      </>
    )
  }
  return null
}
