import React from 'react'
import { Stack, Typography, alpha } from '@mui/material'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { ViewSurveyDetailsLabel } from './ViewSurveyDetailsLabel'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { useColorPalette, useTranslation } from '../../../../../common/hooks'

interface Props {
  surveySchema: SurveySchemaWithoutUser
  status: string
}

export const ViewSurveyTitle: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  status,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  return (
    <Stack direction={'row'} justifyContent={'space-between'} flex={1}>
      <Typography mb={2} mt={1.5} variant="h5">
        {surveySchema.title}
      </Typography>
      <Stack direction={'row'} gap={2}>
        <ViewSurveyDetailsLabel
          title={t(`survey:status.${status.toLowerCase()}`)}
          color={
            status === SurveySchemaStatusEnum.Active
              ? colorPalette.success.main
              : alpha(colorPalette.common.black, 0.2)
          }
        />
        {surveySchema.isGlobal && (
          <ViewSurveyDetailsLabel
            title={t('survey:survey.isGlobal')}
            color={colorPalette.warning.main}
          />
        )}
      </Stack>
    </Stack>
  )
}
