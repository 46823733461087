import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import React, { useMemo, useState } from 'react'
import {
  BetmenListFilter,
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../common/components'
import { useColorPalette, useTranslation } from '../../../../common/hooks'
import { UsePatientsListReturn } from '../../../../common/hooks/usePatientsList'
import { useNotifications } from '../../../../common/providers'
import {
  PatientFilter,
  deleteDoctorsOwnPatients as BulkDismissResult,
} from '../../../../models/graphqlTypes'
import { MessageIcon } from '../../../../common/icons/MessageIcon'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MedicationIcon from '@mui/icons-material/Medication'
import {
  AddSurveyFormValues,
  AddSurveyModal,
} from '../../Treatments/components/Survey/AddSurveyModal'
import { useSendSurveyToUsers } from '../../SurveyStore/hooks/useSendSurveyToUsers'
import { PatientListItemType } from '../types/patientList.types'
import { SendMessageToPatientsModal } from './SendMessageToPatientsModal'
import { useDeleteDoctorOwnPatients } from '../hooks/useDeleteDoctorOwnPatients'
import { DeletePatientsResultModal } from './DeletePatientsResultModal'
import { OpenFilterPanelButton } from '../../../../common/components/FilterPanelButtons/OpenFilterPanelButton'

export interface PatientListActionsProps
  extends Pick<UsePatientsListReturn, 'data' | 'refetch'> {
  filterInput: PatientFilter
  setFilterInput: (filter: PatientFilter) => void
  selectedPatients: PatientListItemType[]
  setSelectedPatients: (ids: PatientListItemType[]) => void
  isFilterPanelOpen: boolean
  handleFilterOpen: () => void
}

const PatientListActions: React.FC<
  React.PropsWithChildren<PatientListActionsProps>
> = ({
  data,
  refetch,
  filterInput,
  setFilterInput,
  selectedPatients,
  setSelectedPatients,
  ...props
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { setSuccessToast, setErrorToast } = useNotifications()

  const handleSearch = (searchInput: string) => {
    setFilterInput({
      ...filterInput,
      nameOrTAJ: searchInput,
    })
  }

  const showActions = useMemo(
    () => data.some((patient) => patient.isActive),
    [data]
  )

  const clearSelectedIds = () => setSelectedPatients([])

  const handleSelectAll = () => {
    const selectablePatientList = data.filter((patient) => patient.isActive)
    if (selectedPatients.length === 0) {
      setSelectedPatients(selectablePatientList)
    } else {
      clearSelectedIds()
    }
  }

  const [isSurveyModalOpen, setSurveyModalOpen] = useState<boolean>(false)

  const [sendSurveyToPatients] = useSendSurveyToUsers({
    onCompleted: () => {
      setSuccessToast('successSendSurvey')
      setSurveyModalOpen(false)
      handleSelectAll()
    },
    onError: (e) => setErrorToast(e),
  })

  const handleSendSurvey = async (formValues: AddSurveyFormValues) => {
    sendSurveyToPatients({
      variables: {
        surveySchemaId: formValues.surveySchema?.id ?? '',
        userIds: selectedPatients.map((patient) => patient.user?.id ?? ''),
        fillableFrom: formValues.fillableFromDate,
        fillableUntil: formValues.fillableUntilDate,
      },
    })
  }

  const [isMessagePanelOpen, setMessagePanelOpen] = useState<boolean>(false)
  const [confirmDeletePatients, setConfirmDeletePatients] =
    useState<ConfirmDialogValue>(false)
  const [deleteDoctorsOwnPatients] = useDeleteDoctorOwnPatients()
  const [deletedPatients, setDeletedPatients] =
    useState<BulkDismissResult | null>(null)

  const handleDelete = async () => {
    if (selectedPatients.length) {
      await deleteDoctorsOwnPatients({
        variables: {
          patientIds: selectedPatients.map((patient) => patient.id),
        },
        onCompleted: (deletedPatients: BulkDismissResult) => {
          setDeletedPatients(deletedPatients)
          refetch()
        },
      })
    }
  }

  const handleCloseDeletePatientsResultModal = () => {
    setDeletedPatients(null)
    clearSelectedIds()
  }

  return (
    <>
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        <BetmenListFilter
          searchTerm={filterInput.nameOrTAJ ?? ''}
          searchPlaceholder={t('patients:searchPatient')}
          handleSearch={(searchTerm) => handleSearch(searchTerm ?? '')}
        />

        <OpenFilterPanelButton filterInput={filterInput} {...props} />
      </Stack>

      {showActions && (
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: colorPalette.common.white,
            borderRadius: 1,
          }}
          mb={1}
          px={2}
          gap={1}
          flexWrap={'wrap'}
        >
          <Box width={40}>
            <Checkbox
              checked={
                data.length > 0 && selectedPatients.length === data.length
              }
              indeterminate={
                selectedPatients.length > 0 &&
                selectedPatients.length !== data.length
              }
              onChange={handleSelectAll}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            />
          </Box>

          {selectedPatients.length > 0 && (
            <>
              <Button
                size="small"
                color="primary"
                variant="text"
                sx={{ paddingX: '16px' }}
                startIcon={<MessageIcon />}
                onClick={() => setMessagePanelOpen(true)}
              >
                {t('patients:actions.message')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="text"
                sx={{ paddingX: '16px' }}
                startIcon={<ListAltIcon />}
                onClick={() => setSurveyModalOpen(true)}
              >
                {t('patients:actions.survey')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="text"
                sx={{ paddingX: '16px' }}
                startIcon={<MedicationIcon />}
              >
                {t('patients:actions.treatment')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={() => setConfirmDeletePatients(true)}
                sx={{ paddingX: '16px' }}
                startIcon={<ExitToAppIcon />}
              >
                {t('patients:actions.delete')}
              </Button>
            </>
          )}
          <Typography marginLeft="auto" variant="smallItalic">
            {t('patients:selectedPatients', {
              count: selectedPatients.length,
            })}
          </Typography>
        </Stack>
      )}

      {isMessagePanelOpen && (
        <SendMessageToPatientsModal
          isOpen={isMessagePanelOpen}
          toggleIsOpen={setMessagePanelOpen}
          selectedPatients={selectedPatients}
          clearSelectedPatients={clearSelectedIds}
        />
      )}

      {isSurveyModalOpen && (
        <AddSurveyModal
          params={{ isOpen: isSurveyModalOpen }}
          onSave={handleSendSurvey}
          onClose={() => setSurveyModalOpen(false)}
          title={t('patients:survey.sendTitle')}
          submitButtonText={t('patients:survey.sendButton')}
        />
      )}

      {confirmDeletePatients && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmDeletePatients, setConfirmDeletePatients]}
          text={t(`patients:confirmDeletePatients`)}
          onAccept={() => handleDelete()}
        />
      )}

      {deletedPatients && (
        <DeletePatientsResultModal
          selectedPatients={selectedPatients}
          deletedPatients={deletedPatients}
          onClose={handleCloseDeletePatientsResultModal}
        />
      )}
    </>
  )
}

export { PatientListActions }
