import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveySchemaForResults,
  getSurveySchemaByIdVariables as getSurveySchemaForResultsVariables,
} from '../../../../models/graphqlTypes'

import { GET_SURVEY_SCHEMA_FOR_RESULTS } from '../operations/surveyOperations'
import { SurveySchemaById } from '../types/surveyStore.types'
import { useMemo } from 'react'
import { useMe } from '../../../../common/hooks/useMe'

type Options = QueryHookOptions<
  getSurveySchemaForResults,
  getSurveySchemaForResultsVariables
>

type Return = {
  surveySchema?: SurveySchemaById
  loading: boolean
  refetch: () => void
}
export const useSurveySchemaForResults = (options: Options = {}): Return => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const { data, loading, refetch } = useQuery<
    getSurveySchemaForResults,
    getSurveySchemaForResultsVariables
  >(GET_SURVEY_SCHEMA_FOR_RESULTS, options)

  const surveySchema = data?.getSurveySchemaById

  const extendedSurveySchema = useMemo(() => {
    const isOwnSurvey = surveySchema?.user?.id === me?.id
    const isOwnerOrAdmin = isOwnSurvey || !!me?.isAdmin
    return surveySchema
      ? {
          ...surveySchema,
          isOwnSurvey,
          isOwnerOrAdmin,
        }
      : undefined
  }, [me?.id, me?.isAdmin, surveySchema])

  return {
    loading,
    surveySchema: extendedSurveySchema,
    refetch,
  }
}
