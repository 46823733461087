import { Button, Stack, Typography } from '@mui/material'
import { findIndex, isFunction } from 'lodash'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { ANSWER_TIME_FOR_QUESTION_IN_MIN } from '../../utils/survey.util'
import { QuestionInSection, Section } from '../EditSurvey/editSurvey.types'
import { FillQuestion } from '../FillQuestion/FillQuestion'
import { FillSurveyCard } from '../../../../patient/PatientEvents/components/FillSurvey/FillSurveyCard'
import { surveyItemDetails } from '../../../../../models/graphqlTypes'

interface Props {
  orderedSections: Section[]
  numberOfQuestionsInSurvey: number
  surveyDetails?: surveyItemDetails
  onSubmit?: () => void
  onClose?: () => void
}

export const FillSurvey: React.FC<React.PropsWithChildren<Props>> = ({
  orderedSections,
  numberOfQuestionsInSurvey,
  surveyDetails,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { watch, handleSubmit } = useFormContext()

  const [sectionHistory, setSectionHistory] = useState<number[]>([0])
  const [actualIndexInHistory, setActualIndexInHistory] = useState<number>(0)

  const isPreview = !isFunction(onSubmit)

  const [isCloseConfirmOpen, setIsCloseConfirmOpen] =
    useState<ConfirmDialogValue>(false)

  const filteredSections = orderedSections.filter(
    (section) => !!section.questions.length
  )
  const actualSectionIndex = sectionHistory[actualIndexInHistory]
  const actualSection = filteredSections[actualSectionIndex]

  const jumpToPreviousSection = () => {
    setSectionHistory((prevHistory) =>
      prevHistory.slice(0, actualIndexInHistory)
    )
    setActualIndexInHistory(actualIndexInHistory - 1)
  }

  const jumpToNextSection = handleSubmit(() => {
    const lastIndexInHistory = sectionHistory.length - 1
    if (lastIndexInHistory > actualIndexInHistory) {
      setActualIndexInHistory(lastIndexInHistory)
    } else {
      const newSectionIndex = actualIndexInHistory + 1
      setSectionHistory([...sectionHistory, actualSectionIndex + 1])
      setActualIndexInHistory(newSectionIndex)
    }
  })

  const jumpToSectionOrder = (nextSectionOrder: number) => {
    const indexToSet = findIndex(filteredSections, ['order', nextSectionOrder])
    setSectionHistory([
      ...sectionHistory.slice(0, actualIndexInHistory + 1),
      indexToSet,
    ])
  }

  return (
    <>
      <FillSurveyCard
        onClose={() => setIsCloseConfirmOpen(true)}
        surveyDetails={surveyDetails}
      >
        <Stack
          gap={1.5}
          pb={2}
          sx={{ borderBottom: `solid 1px ${colorPalette.grey[200]}` }}
        >
          <Typography style={{ whiteSpace: 'pre-line' }} variant="italic">
            {watch('patientDescription')}
          </Typography>
          <Typography variant="body2">
            {t('survey:description.time', {
              time: numberOfQuestionsInSurvey * ANSWER_TIME_FOR_QUESTION_IN_MIN,
            })}
          </Typography>
          <Typography variant="italic">
            {t('survey:description.thankyou')}
          </Typography>
        </Stack>
        <Stack gap={3} p={2}>
          <Typography align="center" variant="h5">
            {actualSection ? actualSection.name : t('survey:noSections')}
          </Typography>
          {actualSection &&
            actualSection.questions.map(
              (question: QuestionInSection, index: number) => (
                <FillQuestion
                  key={question.idForRender}
                  questionOrder={index + 1}
                  question={question}
                  setNextSectionOrder={jumpToSectionOrder}
                  isPreview={isPreview}
                />
              )
            )}
        </Stack>
        <Stack direction="row" justifyContent="center" gap={2}>
          {actualSectionIndex > 0 && (
            <Button onClick={jumpToPreviousSection}>{t('common:back')}</Button>
          )}
          {actualSectionIndex < filteredSections.length - 1 && (
            <Button onClick={jumpToNextSection}>{t('common:continue')}</Button>
          )}
          {actualSectionIndex === filteredSections.length - 1 &&
            isFunction(onSubmit) && (
              <Button data-cy="FillSurvey-Button-submit" onClick={onSubmit}>
                {t('common:submit')}
              </Button>
            )}
        </Stack>
      </FillSurveyCard>

      <ConfirmDialog
        isAlertingDialog
        valueState={[isCloseConfirmOpen, setIsCloseConfirmOpen]}
        text={t(`patients:survey.confirmClose`)}
        onAccept={() => isFunction(onClose) && onClose()}
      />
    </>
  )
}
