import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import {
  SurveyActionsMenu,
  SurveyActionsMenuProps,
} from '../SurveyActions/SurveyActionsMenu'
import { SurveyResultHeaderForm } from '../../types/surveyStore.types'

export const SurveyListMore: React.FC<
  React.PropsWithChildren<SurveyActionsMenuProps>
> = ({ surveySchema, ...props }) => {
  const formMethods = useForm<SurveyResultHeaderForm>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    },
  })

  useEffect(() => {
    formMethods.reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [formMethods, surveySchema])

  return (
    <FormProvider {...formMethods}>
      <SurveyActionsMenu surveySchema={surveySchema} {...props} />
    </FormProvider>
  )
}
