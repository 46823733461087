import { Box, Button, Link, Tab, Tabs, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { BetmenList } from '../BetmenList/BetmenList'
import { BetmenListActions } from '../BetmenList/BetmenListActions'
import { BetmenListBody } from '../BetmenList/BetmenListBody'
import { BetmenListFilter } from '../BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../BetmenList/BetmenListItemCardCell'
import { AppointmentStatusComponent } from './AppointmentStatus'
import { Avatar } from '../Avatar'
import { useDashboardAppointments } from './hooks/useDashboardAppointments'
import { useSelectedLanguage } from '../../hooks/useSelectedLanguage'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { useUserProfile } from '../../hooks/useUserProfile'
import { useUserType } from '../../hooks/useUserType'
import { AppointmentStatus } from '../../../models/graphqlTypes'
import { formatQueryString } from '../../../utils/formatQueryString'
import { DashboardAppointment } from './dashboard.types'
import { Tab as TabType } from '../BetmenPage'

const DoctorAppointmentsOnDashboard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const tabs: TabType[] = [
    { label: t('home:appointments.today') },
    { label: t('home:appointments.tomorrow') },
    {
      label: t('home:appointments.week', {
        day: 7,
      }),
    },
    {
      label: t('home:appointments.week', {
        day: 30,
      }),
    },
    {
      label: t('home:appointments.week', {
        day: 90,
      }),
      'data-cy': 'DoctorAppointmentsOnDashboard-Tab-90day',
    },
  ]

  const userType = useUserType()
  const profile = useUserProfile()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')

  const selectedLanguage = useSelectedLanguage()

  const startDate = useMemo(() => {
    switch (selectedTabIndex) {
      case 1:
        return dayjs().add(1, 'day').startOf('day').toISOString()
      default:
        return dayjs().add(0, 'day').startOf('day').toISOString()
    }
  }, [selectedTabIndex])

  const endDate = useMemo(() => {
    switch (selectedTabIndex) {
      case 1:
        return dayjs().add(1, 'day').endOf('day').toISOString()
      case 2:
        return dayjs().add(7, 'day').endOf('day').toISOString()
      case 3:
        return dayjs().add(30, 'day').endOf('day').toISOString()
      case 4:
        return dayjs().add(90, 'day').endOf('day').toISOString()
      case 0:
      default:
        return dayjs().endOf('day').toISOString()
    }
  }, [selectedTabIndex])

  const PAGE_SIZE = 10
  const {
    appointments,
    isLoading,
    fetchMoreAppointments,
    hasMoreAppointments,
    refetch,
  } = useDashboardAppointments({
    variables: {
      startDate,
      endDate,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, selectedTabIndex])

  const filteredAppointments = appointments.filter(
    ({ status, treatment, info }) => {
      const isFutureAppointment =
        status === AppointmentStatus.Proposed ||
        status === AppointmentStatus.BetmenBooked ||
        status === AppointmentStatus.EesztBooked ||
        status === AppointmentStatus.Expired

      const treatmentTitleTranslated = treatment.title[selectedLanguage]

      const appointmentTitle = info?.doctorTitle[selectedLanguage]

      const patientName = t('common:formattedNameFull', treatment.patient)

      const searchString = `${patientName}_
        ${treatment.patient.tajNumber}_
        ${treatmentTitleTranslated}_
        ${appointmentTitle}_
      `
      const filterArray = searchTerm.replace('-', '').split(' ')

      return (
        isFutureAppointment &&
        filterArray.length ===
          filterArray.filter((filterword) =>
            formatQueryString(searchString).includes(filterword)
          ).length
      )
    }
  )

  const onAppointmentEnter = useCallback(() => {
    const offset = filteredAppointments.length
    fetchMoreAppointments({
      variables: {
        startDate,
        endDate,
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })
  }, [endDate, filteredAppointments, fetchMoreAppointments, startDate])

  const getAppointmentLink = (appointment: DashboardAppointment) => {
    return `/${userType}/patients/${appointment.treatment.patient.id}/${appointment.treatment.id}/${appointment.id}`
  }

  const getDateTimeString = (appointment: DashboardAppointment): string => {
    const dateString = t('common:patientFormattedDate', {
      date: appointment.proposedDate,
    })
    const timeString = t('common:patientFormattedTime', {
      date: appointment.proposedDate,
    })
    const shouldDisplayTime =
      appointment.isBetmenBooked || !!appointment.eesztBookingId

    return `${dateString}${shouldDisplayTime ? `, ${timeString}` : ''}`
  }

  return (
    <BetmenList
      dataSource={filteredAppointments}
      fetchMore={onAppointmentEnter}
      hasMoreItem={hasMoreAppointments}
      loading={isLoading}
      notFoundMessage={t('home:appointments.noEvent')}
      gridColumnSizes={[1, 4, 3, 3, 1]}
      filters={
        <BetmenListFilter
          searchPlaceholder={t('appointment:searchAppointment')}
          handleSearch={(searchTerm) =>
            setSearchTerm(searchTerm?.toLowerCase() ?? '')
          }
          extraStyle={{ position: 'relative', width: '100%', marginBottom: 1 }}
        >
          <Tabs
            value={selectedTabIndex}
            textColor="inherit"
            scrollButtons="auto"
            variant="scrollable"
            onChange={(_, tabIndex) => setSelectedTabIndex(tabIndex)}
            orientation="horizontal"
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              marginInline: 'auto',
              width: 'fit-content',
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} {...tab} />
            ))}
          </Tabs>
        </BetmenListFilter>
      }
      header={
        <BetmenListHeader>
          <BetmenListHeaderCell>{t('common:status')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:appointment')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('treatment:dateAndTime')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:patient')}</BetmenListHeaderCell>
          <BetmenListHeaderCell actions>
            {t('table:header.actions')}
          </BetmenListHeaderCell>
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {filteredAppointments.map((appointment) => (
          <BetmenListItemCard
            key={appointment.id}
            data-cy={`AppointmentOnDashboard-Box-${appointment.id}`}
            click={() => history.push(getAppointmentLink(appointment))}
          >
            <BetmenListItemCardCell>
              <AppointmentStatusComponent status={appointment.status} />
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <Link
                underline="none"
                component={RouterLink}
                to={getAppointmentLink(appointment)}
              >
                <Typography variant="body2">
                  {appointment.info?.doctorTitle[selectedLanguage]}
                </Typography>
              </Link>
              <Typography variant="subtitle2" color="textSecondary">
                {appointment.treatment.title[selectedLanguage]}
              </Typography>
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <Typography variant="body2">
                {t('common:formattedNameFull', {
                  title: profile?.title,
                  firstName: profile?.firstName,
                  lastName: profile?.lastName,
                })}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {getDateTimeString(appointment)}
              </Typography>
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <Box mb={{ xs: 1.5, sm: 0 }} />
              <Box display="flex" alignItems="center">
                <Avatar
                  size="tiny"
                  firstName={appointment.treatment.patient.firstName}
                  lastName={appointment.treatment.patient.lastName}
                />
                <Box pl={1}>
                  {t('common:formattedNameFull', appointment.treatment.patient)}
                </Box>
              </Box>
            </BetmenListItemCardCell>
            <BetmenListActions>
              {appointment.status === AppointmentStatus.Proposed && (
                <Button
                  size="medium"
                  component={RouterLink}
                  to={`${getAppointmentLink(appointment)}/edit`}
                >
                  {t('home:appointments.book')}
                </Button>
              )}
            </BetmenListActions>
          </BetmenListItemCard>
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}

export { DoctorAppointmentsOnDashboard }
