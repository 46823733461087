import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  resendSurvey,
  resendSurveyVariables,
} from '../../../../models/graphqlTypes'
import { RESEND_SURVEY } from '../operations/surveyOperations'

type Options = MutationHookOptions<resendSurvey, resendSurveyVariables>

type Return = MutationTuple<resendSurvey, resendSurveyVariables>

export const useResendSurvey = (options: Options = {}): Return => {
  return useMutation<resendSurvey, resendSurveyVariables>(
    RESEND_SURVEY,
    options
  )
}
