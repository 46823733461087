import { isFunction } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { ConfirmDialog } from '../../../../../common/components/dialogs/ConfirmDialog'
import { ConfirmDialogValue } from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { ScrollablePanel } from '../../../../../common/components/ScrollablePanel'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  InputSurveyQuestionAnswer,
  SurveyStatusEnum,
} from '../../../../../models/graphqlTypes'
import { useStoreActions } from '../../../../../store/store.hooks'
import { FillSurvey } from '../../../../doctor/SurveyStore/components/FillSurvey/FillSurvey'
import { mapSurveySchemaQuestionsToSections } from '../../../../doctor/SurveyStore/utils/survey.mapper'
import {
  getNumberOfQuestionsInSurvey,
  getOrderedSections,
} from '../../../../doctor/SurveyStore/utils/survey.util'
import { usePatientSurveyAnswerUpsert } from '../../hooks/usePatientSurveyAnswerUpsert'
import { usePatientSurveyFinish } from '../../hooks/usePatientSurveyFinish'
import {
  filterFilledAnswers,
  getAreAllQuestionsNotRequired,
  getInitialAnswers,
} from '../../util/fillSurvey.util'
import { FillSurveyForm } from './fillSurvey.types'
import { FillSurveySuccess } from './FillSurveySuccess'
import { PatientSurveyById } from '../../types/patientEvents.types'

interface Props {
  survey: PatientSurveyById
}

export const FillSurveyState: React.FC<React.PropsWithChildren<Props>> = ({
  survey,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const [isSuccessScreenOn, setIsSuccessScreenOn] = useState<boolean>(
    survey.status === SurveyStatusEnum.Finished
  )
  const [isSubmitConfirmOpen, setIsSubmitConfirmOpen] =
    useState<ConfirmDialogValue>(false)

  const sections = useMemo(
    () =>
      mapSurveySchemaQuestionsToSections(
        survey.surveySchema.surveySchemaQuestions || []
      ),
    [survey]
  )

  const orderedSections = getOrderedSections(sections)
  const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)

  const formMethods = useForm<FillSurveyForm>({
    reValidateMode: 'onBlur',
    defaultValues: {
      title: survey?.surveySchema.title || '',
      patientDescription:
        survey?.surveySchema.patientDescription ||
        t('survey:description.placeholder'),
      answers: survey ? getInitialAnswers(survey) : [],
    },
  })

  useEffect(() => {
    // Sometimes form doesn't update automatically if survey changes (e.g.: nav from notification panel).
    // so we need reset, to refresh manually.
    formMethods.reset({
      title: survey?.surveySchema.title || '',
      patientDescription:
        survey?.surveySchema.patientDescription ||
        t('survey:description.placeholder'),
      answers: survey ? getInitialAnswers(survey) : [],
    })
    setIsSuccessScreenOn(survey.status === SurveyStatusEnum.Finished)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey?.id])

  const [upsertOneAnswer] = usePatientSurveyAnswerUpsert()
  const [markSurveyAsFinished] = usePatientSurveyFinish()

  const finishSurvey = (onCompleted?: () => void) =>
    markSurveyAsFinished({
      variables: { id: survey.id },
      onCompleted: () => {
        if (isFunction(onCompleted)) {
          onCompleted()
        }
      },
    })

  const saveAllAnswers = async (filledAnswers: InputSurveyQuestionAnswer[]) =>
    await Promise.all(
      filledAnswers.map((answer: InputSurveyQuestionAnswer) =>
        upsertOneAnswer({
          variables: { inputSurveyQuestionAnswer: answer },
        })
      )
    )

  const displaySuccessfulSaveToastAndGoBack = () => {
    setToast({
      text: t(`notification:surveySuccesfullySent`),
      type: 'success',
    })
    history.push('/patient/home/surveys')
  }

  const onSubmit = () =>
    formMethods.handleSubmit(async (formValues) => {
      const filledAnswers = filterFilledAnswers(
        formValues.answers,
        survey.surveySchema.surveySchemaQuestions
      )
      if (!!filledAnswers.length) {
        try {
          await saveAllAnswers(filledAnswers)
          finishSurvey()
          setIsSuccessScreenOn(true)
        } catch (error) {
          // GraphQl errors are already handled in general, we don't need to set another toast for them
          console.log(error)
        }
      } else {
        if (
          getAreAllQuestionsNotRequired(
            survey.surveySchema.surveySchemaQuestions
          )
        ) {
          // if the survey contains only NOT required questions
          // and the patient didn't fill any of them
          // we display a confirmation modal whether (s)he wants to submit the survey without any answers
          setIsSubmitConfirmOpen(true)
        }
      }
    })()

  const onClose = async () => {
    const filledAnswers = filterFilledAnswers(
      formMethods.getValues().answers,
      survey.surveySchema.surveySchemaQuestions
    )
    if (!!filledAnswers.length) {
      await saveAllAnswers(filledAnswers)
    }
    displaySuccessfulSaveToastAndGoBack()
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <ScrollablePanel smoothScroll={true} title={t('survey:title.fillout')}>
          {isSuccessScreenOn ? (
            <FillSurveySuccess surveyDetails={survey} />
          ) : (
            <FillSurvey
              orderedSections={orderedSections}
              numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
              surveyDetails={survey}
              onSubmit={onSubmit}
              onClose={onClose}
            />
          )}
        </ScrollablePanel>
      </FormProvider>
      <ConfirmDialog
        isAlertingDialog
        valueState={[isSubmitConfirmOpen, setIsSubmitConfirmOpen]}
        text={t(`patients:survey.confirmSubmitEmptyAnswers`)}
        onAccept={() => finishSurvey(displaySuccessfulSaveToastAndGoBack)}
      />
    </>
  )
}
