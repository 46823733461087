import {
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { CloseIcon } from '../../../../../common/icons/CloseIcon'
import { surveyItemDetails } from '../../../../../models/graphqlTypes'
import { useTranslation } from '../../../../../common/hooks'

interface FillSurveyCardProps {
  onClose: () => void
  surveyDetails?: surveyItemDetails
}
export const FillSurveyCard: React.FC<
  React.PropsWithChildren<FillSurveyCardProps>
> = ({ onClose, surveyDetails, children }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const colorPalette = useColorPalette()

  return (
    <Container>
      <Card>
        <CardHeader
          title={
            <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
              <Typography variant="h6" mr={5}>
                {watch('title')}
              </Typography>
              {surveyDetails && (
                <>
                  <Typography variant="subtitle1" mr={5}>
                    {t('patients:survey.header.doctor')}
                    {': '}
                    <strong>
                      {t('common:formattedNameFull', surveyDetails?.doctor)}
                    </strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('patients:survey.header.recipient')}
                    {': '}
                    <strong>
                      {t('common:formattedName', {
                        title: surveyDetails?.recipientProfile.title,
                        firstName: surveyDetails?.recipientProfile.firstName,
                        lastName: surveyDetails?.recipientProfile.lastName,
                      })}
                    </strong>
                  </Typography>
                </>
              )}
            </Stack>
          }
          sx={{ borderBottom: `solid 1px ${colorPalette.grey[200]}` }}
          action={
            <IconButton size="large" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
        ></CardHeader>
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  )
}
