import { Button } from '@mui/material'
import React, { useState } from 'react'
import { SurveyStatusEnum } from '../../../../../models/graphqlTypes'
import dayjs from 'dayjs'
import { PatientSurvey } from '../../../../patient/PatientEvents/types/patientEvents.types'
import { ResendSurveyModal } from '../../../SurveyStore/components/Surveys/ResendSurveyModal'
import { useHistory } from 'react-router-dom'
import { useMe } from '../../../../../common/hooks/useMe'
import {
  canIFillOutSurvey,
  canISeeResults,
  isSurveySentByMe,
} from '../../../../../utils/survey'
import { useTranslation } from '../../../../../common/hooks'
import { SurveyResultTab } from '../../../SurveyStore/components/SurveyResult/surveyResult.types'
import { useSurveyPaths } from '../../../SurveyStore/components/SurveyResult/useSurveyPaths'

interface Props {
  survey: PatientSurvey
}

const SurveyActionButtonSwitcher: React.FC<
  React.PropsWithChildren<Props & { openResendModal: () => void }>
> = ({ survey, openResendModal }) => {
  const { t } = useTranslation('survey')
  const { status, fillableUntil } = survey

  const { surveyResultBaseUrl } = useSurveyPaths()

  const hasExpired = dayjs(fillableUntil).isBefore(new Date())

  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const history = useHistory()

  const canSeeResults = canISeeResults(me)
  const canFillOut = canIFillOutSurvey(survey, me)
  const canResend = isSurveySentByMe(survey, me)

  const openResults = () =>
    history.push(
      `${surveyResultBaseUrl}/${survey.surveySchema.id}/${SurveyResultTab.AggregatedResults}`
    )

  const openFillSurvey = () =>
    history.push(`/${me?.userType.toLowerCase()}/fill-survey/${survey.id}`)

  if (hasExpired && status !== SurveyStatusEnum.Finished) {
    if (!canResend) {
      return null
    }
    return (
      <Button
        variant="text"
        size="small"
        sx={{ paddingX: 0 }}
        onClick={openResendModal}
      >
        {t('button.resend')}
      </Button>
    )
  }

  switch (status) {
    case SurveyStatusEnum.Untouched:
      if (!canFillOut) {
        return null
      }
      return (
        <Button
          variant="text"
          size="small"
          sx={{ paddingX: 0 }}
          onClick={openFillSurvey}
        >
          {t('button.fillout')}
        </Button>
      )
    case SurveyStatusEnum.InProgress:
      if (!canFillOut) {
        return null
      }
      return (
        <Button
          variant="text"
          size="small"
          sx={{ paddingX: 0 }}
          onClick={openFillSurvey}
        >
          {t('button.continue')}
        </Button>
      )

    case SurveyStatusEnum.Finished:
      if (!canSeeResults) {
        return null
      }
      return (
        <Button
          variant="text"
          size="small"
          sx={{ paddingX: 0 }}
          onClick={openResults}
        >
          {t('button.result')}
        </Button>
      )
  }
}

export const SurveyActionButton: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const [isSendSurveyModalOpen, setIsSendSurveyModalOpen] =
    useState<boolean>(false)

  return (
    <>
      <SurveyActionButtonSwitcher
        openResendModal={() => setIsSendSurveyModalOpen(true)}
        {...props}
      />
      <ResendSurveyModal
        open={isSendSurveyModalOpen}
        onClose={() => setIsSendSurveyModalOpen(false)}
        surveyId={props.survey.id}
      />
    </>
  )
}
