import { gql } from '@apollo/client'
import {
  FRAGMENT_SURVEY_ANSWER_CHORE,
  FRAGMENT_SURVEY_ITEM_DETAILS,
  FRAGMENT_SURVEY_LIST_ITEM,
  FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE,
} from '../../../../operations/fragments'

export const GET_APPOINTMENT_COUNT_BY_USER_ID_AND_IN_FUTURE = gql`
  query getAppointmentCountByUserIdAndInFuture {
    appointmentCountByIsOwnAndInFuture
  }
`

export const GET_SURVEY_COUNT_BY_USER_ID_AND_NOT_FINISHED = gql`
  query getSurveyCountByUserIdAndNotFinished {
    surveyCountByIsOwnAndNotFinished
  }
`

export const GET_IS_SURVEY_EXPIRE_SOON_BY_USER_ID_AND_NOT_FINISHED = gql`
  query isSurveyExpireSoonByIsOwnAndNotFinished {
    isSurveyExpireSoonByIsOwnAndNotFinished
  }
`

export const GET_PATIENT_SURVEYS = gql`
  query getSurveys(
    $filterData: SurveyFilterForPatient!
    $paginationInput: PaginationInput
  ) {
    getOwnSurveys(filterData: $filterData, paginationInput: $paginationInput) {
      surveys {
        ...surveyListItem
      }
      total
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`

export const GET_PATIENT_SURVEY_BY_ID = gql`
  query getSurveyById($surveyId: ID!) {
    getSurvey(surveyId: $surveyId) {
      ...surveyItemDetails
      surveySchema {
        id
        title
        patientDescription
        surveySchemaQuestions {
          ...surveySchemaQuestionChore
        }
      }
      surveyQuestionAnswers {
        surveyQuestionId
        ...surveyAnswerChore
      }
    }
  }
  ${FRAGMENT_SURVEY_ITEM_DETAILS}
  ${FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE}
  ${FRAGMENT_SURVEY_ANSWER_CHORE}
`

export const UPSERT_SURVEY_QUESTION_ANSWER = gql`
  mutation upsertSurveyQuestionAnswer(
    $inputSurveyQuestionAnswer: InputSurveyQuestionAnswer!
  ) {
    upsertSurveyQuestionAnswer(
      inputSurveyQuestionAnswer: $inputSurveyQuestionAnswer
    ) {
      id
    }
  }
`

export const FINISH_SURVEY = gql`
  mutation finish($id: String!) {
    finishSurvey(surveyId: $id) {
      id
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation uploadFile($documentType: String!, $file: Upload!) {
    uploadDocument(file: $file, documentType: $documentType)
  }
`
