import { OverrideProps } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { RssFeed as RssFeedIcon } from '@mui/icons-material'
import { ProfileIcon } from '../icons/ProfileIcon'
import { CogIcon } from '../icons/CogIcon'
import { HelpIcon } from '../icons/HelpIcon'

export type ProfileMenuItemProps = {
  link?: string
  translationKey: string
  IconComponent: React.FC<
    React.PropsWithChildren<
      OverrideProps<SvgIconTypeMap<unknown, 'svg'>, 'svg'>
    >
  >
  slug: string
}

export type MenuItem = {
  link: string
  translationKey: string
  slug: string
}

export const patientMenuItems: MenuItem[] = [
  {
    link: '/patient/home',
    translationKey: 'menu:patient.myTreatments',
    slug: 'appointments',
  },
  {
    link: '/patient/doctors',
    translationKey: 'menu:patient.myDoctors',
    slug: 'doctors',
  },
]

export const adminMenuItems: MenuItem[] = [
  {
    link: '/admin/home',
    translationKey: 'footer:menu.admin',
    slug: 'home',
  },
  {
    link: '/admin/treatment-options',
    translationKey: 'menu:doctor.templates',
    slug: 'treatmentOptions',
  },
  {
    link: '/admin/surveys',
    translationKey: 'menu:doctor.surveys',
    slug: 'surveys',
  },
]

export const doctorMenuItems: MenuItem[] = [
  {
    link: '/doctor/home/appointments',
    translationKey: 'menu:doctor.home',
    slug: 'doctor',
  },
  {
    link: '/doctor/patients',
    translationKey: 'menu:doctor.patients',
    slug: 'patients',
  },
  {
    link: '/doctor/assistants',
    translationKey: 'menu:doctor.assistants',
    slug: 'assistants',
  },
  {
    link: '/doctor/treatment-options',
    translationKey: 'menu:doctor.templates',
    slug: 'treatmentOptions',
  },
  {
    link: '/doctor/surveys',
    translationKey: 'menu:doctor.surveys',
    slug: 'surveys',
  },
]

export const assistantMenuItems: MenuItem[] = [
  {
    link: '/assistant/home',
    translationKey: 'menu:doctor.home',
    slug: 'home',
  },
  {
    link: '/assistant/patients',
    translationKey: 'menu:doctor.patients',
    slug: 'patients',
  },
  {
    link: '/assistant/doctors',
    translationKey: 'menu:patient.myDoctors',
    slug: 'doctors',
  },
]

export const sharedProfileMenuItems: ProfileMenuItemProps[] = [
  {
    link: '/settings',
    translationKey: 'menu:settings',
    IconComponent: CogIcon,
    slug: 'settings',
  },
  {
    link: '/about',
    translationKey: 'footer:menu.about',
    IconComponent: HelpIcon,
    slug: 'about',
  },
  {
    link: '/eeszt-rss',
    translationKey: 'menu:eesztRss',
    IconComponent: RssFeedIcon,
    slug: 'eeszt-rss',
  },
]

export const assistantProfileMenuItems: ProfileMenuItemProps[] = [
  {
    link: '/assistant/profile',
    translationKey: 'profile:doctor.profile',
    IconComponent: ProfileIcon,
    slug: 'profile',
  },
]

export const patientProfileMenuItems: ProfileMenuItemProps[] = [
  {
    link: '/patient/profile',
    translationKey: 'profile:doctor.profile',
    IconComponent: ProfileIcon,
    slug: 'profile',
  },
]

export const adminProfileMenuItems: ProfileMenuItemProps[] = [
  {
    link: '/admin/institutions',
    translationKey: 'menu:doctor.institutionAdmin',
    IconComponent: CogIcon,
    slug: 'institutions',
  },
  {
    link: '/admin/locations',
    translationKey: 'footer:menu.locationAdmin',
    IconComponent: CogIcon,
    slug: 'locations',
  },
  {
    link: '/admin/appointments',
    translationKey: 'menu:doctor.appointmentAdmin',
    IconComponent: CogIcon,
    slug: 'appointments',
  },
]

export const doctorProfileMenuItems: ProfileMenuItemProps[] = [
  {
    link: '/doctor/profile',
    translationKey: 'profile:doctor.profile',
    IconComponent: ProfileIcon,
    slug: 'profile',
  },
  {
    link: '/doctor/appointments',
    translationKey: 'menu:doctor.appointmentAdmin',
    IconComponent: CogIcon,
    slug: 'appointments',
  },
]
