import { RemoveRedEye } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { Button, ButtonProps } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
interface Props extends ButtonProps {
  surveySchema: SurveySchemaWithoutUser
}

export const EditSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const classes = useStylesForSurveyActionButtons()

  const { watch } = useFormContext()
  const status = watch('status')

  const isEditable = useMemo(
    () =>
      status !== SurveySchemaStatusEnum.Archived && surveySchema.isOwnerOrAdmin,
    [status, surveySchema.isOwnerOrAdmin]
  )

  const onEdit = () =>
    history.push(`/${userType?.toLowerCase()}/survey/${surveySchema.id}`)

  return (
    <>
      <Button
        classes={{ textPrimary: classes.textPrimary }}
        variant="text"
        startIcon={isEditable ? <EditIcon /> : <RemoveRedEye />}
        onClick={onEdit}
        {...buttonProps}
        data-cy={'EditSurveyButton'}
      >
        {isEditable ? t('common:edit') : t('common:view')}
      </Button>
    </>
  )
}
