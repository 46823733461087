import React from 'react'
import {
  canUnsubscribeFromTreatmentSchema,
  isTreatmentSchemaEditable,
  isTreatmentSchemaShareable,
} from '../../../../../utils/treatmentSchemaHelper'
import { useMe } from '../../../../../common/hooks/useMe'
import { TreatmentSchemaShareButton } from './TreatmentSchemaShareButton'
import { TreatmentSchemaUnsubscribeButton } from './TreatmentSchemaUnsubscribeButton'
import { TreatmentSchemaRemoveButton } from './TreatmentSchemaRemoveButton'
import { TreatmentSchemaEditButton } from './TreatmentSchemaEditButton'
import makeStyles from '@mui/styles/makeStyles'
import { TreatmentSchemaDuplicateButton } from './TreatmentSchemaDuplicateButton'
import { TreatmentSchema } from '../../types/treatmentSchemas.types'

export const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.grey[200],
    },
  },
}))

interface Props {
  treatmentSchema: TreatmentSchema
  refetchTreatments?: () => void
}

export const TreatmentSchemaActionsMenu: React.FC<
  React.PropsWithChildren<Props>
> = ({ treatmentSchema, refetchTreatments }) => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const userId = me?.id

  const canEdit = isTreatmentSchemaEditable(treatmentSchema, userId)
  const canShare = isTreatmentSchemaShareable(treatmentSchema, userId)
  const canUnsubscribe = canUnsubscribeFromTreatmentSchema(
    treatmentSchema,
    userId
  )
  const canDelete = canEdit && treatmentSchema.isDeletable

  const classes = useStyles()

  return (
    <>
      <TreatmentSchemaEditButton
        treatmentSchema={treatmentSchema}
        sx={{ justifyContent: 'flex-start' }}
        classes={{ textPrimary: classes.textPrimary }}
      />
      <TreatmentSchemaDuplicateButton
        treatmentSchemaId={treatmentSchema.id}
        sx={{ justifyContent: 'flex-start' }}
        classes={{ textPrimary: classes.textPrimary }}
        refetchTreatments={refetchTreatments}
      />
      {canShare && (
        <TreatmentSchemaShareButton
          treatmentSchema={treatmentSchema}
          sx={{ justifyContent: 'flex-start' }}
          classes={{ textPrimary: classes.textPrimary }}
        />
      )}
      {canUnsubscribe && (
        <TreatmentSchemaUnsubscribeButton
          treatmentSchema={treatmentSchema}
          sx={{ justifyContent: 'flex-start' }}
          classes={{ textPrimary: classes.textPrimary }}
        />
      )}
      {canDelete && (
        <TreatmentSchemaRemoveButton
          treatmentSchemaId={treatmentSchema.id}
          refetchTreatments={refetchTreatments}
        />
      )}
    </>
  )
}
