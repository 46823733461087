import React from 'react'
import { Box } from '@mui/material'
import { PageWithScrollableCard } from '../common/layout/PageWithScrollableCard'
import { useTranslation } from '../common/hooks'

const PrivacyStatement: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" height="100%">
      <PageWithScrollableCard
        pageTitle={t('footer:menu.privacyStatement')}
        markdownTextInCard={t('common:privacyStatement')}
      />
    </Box>
  )
}

export { PrivacyStatement }
