import React from 'react'
import { Link, Typography } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { isValidHttpUrl } from '../../../../../common/components/Reference/referenceUtil'

interface Props {
  surveySchema: SurveySchemaWithoutUser
}

export const ViewSurveyDetailsCore: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveySchema }) => {
  const { t } = useTranslation()

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {t('treatment:protocol.profession')}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle2">
              {surveySchema.professions
                .map((profession) => profession.name)
                .join(', ')}
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {t('treatment:protocol.bnoCode')}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle2">
              {!surveySchema.bnoCodes.length
                ? '-'
                : surveySchema.bnoCodes
                    .map((item) => `${item.code} - ${item.title}`)
                    .join(', ')}
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {t('treatment:protocol.snowmedCode')}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle2">
              {!surveySchema.snomedCode ? '-' : surveySchema.snomedCode}
            </Typography>
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>
            <Typography variant="subtitle1">
              {t('treatment:protocol.url')}
            </Typography>
          </td>
          <td>
            {!surveySchema.referenceUrls?.length
              ? '-'
              : surveySchema.referenceUrls?.map((reference, i) => (
                  <Typography key={i} variant="subtitle2">
                    {isValidHttpUrl(reference.url) ? (
                      <Link
                        variant="subtitle1"
                        href={reference.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {reference.name}
                      </Link>
                    ) : (
                      reference.url
                    )}
                  </Typography>
                ))}
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1" sx={{ pr: 4 }}>
              {t('survey:surveys.category')}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle2">
              {surveySchema.categories
                .map((category) =>
                  t(`survey:survey.type.${category.toLowerCase()}`)
                )
                .join(', ')}
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {t('survey:description.title')}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle1">
              {surveySchema.description}
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
