import { MeType } from '../common/types/me.type'
import { UserType } from '../models/graphqlTypes'
import { DoctorPatientSurvey } from '../views/doctor/Treatments/types/surveys.types'

export const isRecipientPatient = (survey: DoctorPatientSurvey) =>
  survey.recipientProfile.user?.userType === UserType.Patient

export const isRecipientDoctor = (survey: DoctorPatientSurvey) =>
  survey.recipientProfile.user?.userType === UserType.Doctor

export const isSurveySentByMe = (survey: DoctorPatientSurvey, me?: MeType) =>
  survey.doctor.id === me?.profile.id

export const isSurveySentToMe = (survey: DoctorPatientSurvey, me?: MeType) =>
  survey.recipientProfile.user?.id === me?.id

export const canIFillOutSurvey = (survey: DoctorPatientSurvey, me?: MeType) =>
  (isRecipientPatient(survey) &&
    isSurveySentByMe(survey, me) &&
    me?.userType === UserType.Doctor) ||
  isSurveySentToMe(survey, me)

export const canISeeResults = (me?: MeType) => me?.userType === UserType.Doctor
