import { Stack, Typography } from '@mui/material'
import React from 'react'
import { DoctorPatientSurvey } from '../../../Treatments/types/surveys.types'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { formatTAJ } from '../../../../../utils'
import { UserType } from '../../../../../models/graphqlTypes'
import { makeTruncatedTextStyles } from '../../../../../common/components/ResponsiveTable/utils'

interface Props {
  survey: DoctorPatientSurvey
}
export const RecipientDetails: React.FC<React.PropsWithChildren<Props>> = ({
  survey,
}) => {
  const { t } = useTranslation()

  return (
    <Stack>
      {t('common:formattedNameFull', {
        title: survey.recipientProfile.title,
        firstName: survey.recipientProfile.firstName,
        lastName: survey.recipientProfile.lastName,
      })}
      <Typography
        variant="subtitle1"
        style={makeTruncatedTextStyles('inherit')}
      >
        {survey.recipientProfile.user?.userType === UserType.Patient ? (
          <>TAJ: {formatTAJ(survey.recipientProfile.extraInfo)}</>
        ) : (
          <>{survey.recipientProfile.extraInfo}</>
        )}
      </Typography>
    </Stack>
  )
}
