import React from 'react'
import { Box } from '@mui/material'
import { EditSurveyButton } from './EditSurveyButton'
import { DeleteSurveyButton } from './DeleteSurveyButton'
import { SendSurveyButton } from './SendSurveyButton'
import { PublishSurveyButton } from './PublishSurveyButton'
import { DuplicateSurveyButton } from './DuplicateSurveyButton'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { UnsubscribeSurveyButton } from './UnsubscribeSurveyButton'
import { ShareSurveyButton } from './ShareSurveyButton'

export interface SurveyActionsMenuProps {
  surveySchema: SurveySchemaWithoutUser
  refetchSurveys?: () => void
  showEditButton?: boolean
  showPublishButton?: boolean
}

export const SurveyActionsMenu: React.FC<
  React.PropsWithChildren<SurveyActionsMenuProps>
> = ({
  surveySchema,
  refetchSurveys,
  showEditButton = false,
  showPublishButton = true,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showEditButton && (
        <EditSurveyButton
          surveySchema={surveySchema}
          sx={{ justifyContent: 'flex-start' }}
        />
      )}
      <ShareSurveyButton
        surveySchema={surveySchema}
        sx={{ justifyContent: 'flex-start' }}
      />
      <SendSurveyButton
        surveySchema={surveySchema}
        sx={{ justifyContent: 'flex-start' }}
      />
      <DuplicateSurveyButton
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        sx={{ justifyContent: 'flex-start' }}
      />
      {showPublishButton && (
        <PublishSurveyButton
          surveySchema={surveySchema}
          variant="text"
          sx={{ justifyContent: 'flex-start' }}
        />
      )}
      <DeleteSurveyButton
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        sx={{ justifyContent: 'flex-start' }}
      />
      <UnsubscribeSurveyButton
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        sx={{ justifyContent: 'flex-start' }}
      />
    </Box>
  )
}
