import PreviewIcon from '@mui/icons-material/Preview'
import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveyContext } from '../EditSurveyState'
import { PreviewSurveyButton } from '../SurveyActions/PreviewSurveyButton'
import { PublishSurveyButton } from '../SurveyActions/PublishSurveyButton'
import { SaveSurveyButton } from '../SurveyActions/SaveSurveyButton'
import { Section } from './editSurvey.types'
import { SurveySchemaById } from '../../types/surveyStore.types'
import { SurveyListMore } from '../Surveys/SurveyListMore'
import { MoreActionsMenu } from '../../../../../common/components/MoreActionsMenu/MoreActionsMenu'

interface Props {
  surveySchema?: SurveySchemaById
  refetchSurveySchema?: () => void
  sections: Section[]
  isPreviewOnState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const EditSurveyActions: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  sections,
  isPreviewOnState,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { isReadonly, shouldSave } = useContext(SurveyContext)

  const [isPreviewOn] = isPreviewOnState

  return (
    <>
      <Grid
        container
        direction="row"
        gap={1}
        flex={1}
        justifyContent="flex-end"
      >
        {isPreviewOn && (
          <Box
            bgcolor={colorPalette.warning.main}
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              gap: 1,
              mx: 2,
            }}
          >
            <PreviewIcon />
            <Typography variant="h6">
              {t('survey:survey.preview.title')}
            </Typography>
          </Box>
        )}

        <PreviewSurveyButton isPreviewOnState={isPreviewOnState} />
        {!isReadonly && shouldSave && (
          <SaveSurveyButton surveySchema={surveySchema} sections={sections} />
        )}
        {surveySchema && !shouldSave && (
          <PublishSurveyButton surveySchema={surveySchema} />
        )}

        <Grid item>
          {surveySchema && (
            <MoreActionsMenu>
              <SurveyListMore
                surveySchema={surveySchema}
                showPublishButton={false}
              />
            </MoreActionsMenu>
          )}
        </Grid>
      </Grid>
    </>
  )
}
