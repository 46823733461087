import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphqlTypes'
import { AppointmentAdmin } from '../views/admin/pages/AppointmentAdmin'

import { DoctorAssistants } from '../views/doctor/DoctorAssistants/DoctorAssistants'
import { DoctorDashboard } from '../views/doctor/DoctorDashboard/DoctorDashboard'
import { DoctorPatient } from '../views/doctor/DoctorPatients/components/DoctorPatient'
import { DoctorPatients } from '../views/doctor/DoctorPatients/DoctorPatients'
import { DoctorProfile } from '../views/doctor/DoctorProfile/DoctorProfile'
import { EditSurveyState } from '../views/doctor/SurveyStore/components/EditSurveyState'
import { GetSurvey } from '../views/doctor/SurveyStore/components/GetSurvey'
import { GetSurveyResult } from '../views/doctor/SurveyStore/components/SurveyResult/GetSurveyResult'
import { SurveyStore } from '../views/doctor/SurveyStore/SurveyStore'
import { TreatmentSchemaView } from '../views/doctor/TreatmentSchema/components/TreatmentSchemaView'
import { TreatmentSchemas } from '../views/doctor/TreatmentSchema/TreatmentSchemas'
import { RedirectHandler } from './RedirectHandler'
import { GetFillSurvey } from '../views/patient/PatientEvents/components/GetFillSurvey'

const DoctorRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userType = useUserType()

  if (userType !== UserType.Doctor) {
    return <Redirect to={`/${userType}/home`} />
  }

  return (
    <Switch>
      <Route
        path={`/doctor/patients/:patientId/:treatmentId/:appointmentId/:action`}
      >
        <DoctorPatient />
      </Route>
      <Route path={`/doctor/patients/:patientId/:treatmentId/:appointmentId/`}>
        <DoctorPatient />
      </Route>
      <Route path={`/doctor/patients/:patientId/:treatmentId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/doctor/patients/:patientId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/doctor/patients`}>
        <DoctorPatients />
      </Route>
      <Route path={`/doctor/assistants`}>
        <DoctorAssistants />
      </Route>
      <Route path="/doctor/treatment-options/:treatmentOptionId">
        <TreatmentSchemas />
      </Route>
      <Route path="/doctor/treatment-options">
        <TreatmentSchemas />
      </Route>
      <Route path="/doctor/treatment-option/:treatmentOptionId">
        <TreatmentSchemaView />
      </Route>
      <Route path="/doctor/profile">
        <DoctorProfile />
      </Route>

      <Route path="/doctor/home/appointments">
        <DoctorDashboard />
      </Route>
      <Route path="/doctor/home/surveys">
        <DoctorDashboard />
      </Route>

      <Redirect from="/doctor/home" to="/doctor/home/appointments" />

      <Route path="/doctor/survey/:surveySchemaId">
        <GetSurvey />
      </Route>
      <Route path="/doctor/add-survey">
        <EditSurveyState />
      </Route>
      <Route path="/doctor/survey-result/:surveySchemaId">
        <GetSurveyResult />
      </Route>
      <Route path="/doctor/fill-survey/:surveyId">
        <GetFillSurvey />
      </Route>
      <Route path="/doctor/surveys">
        <SurveyStore />
      </Route>

      <Route
        path={['/doctor/appointments/:appointmentId', '/doctor/appointments']}
      >
        <AppointmentAdmin />
      </Route>

      <Route>
        <RedirectHandler />
      </Route>
    </Switch>
  )
}

export { DoctorRoutes }
