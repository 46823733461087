import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphqlTypes'
import { AssistantDashboard } from '../views/assistant/AssistantDashboard'
import { AssistantProfile } from '../views/assistant/AssistantProfile'

import { AssistantDoctors } from '../views/assistant/AssistantDoctors'
import { DoctorPatient } from '../views/doctor/DoctorPatients/components/DoctorPatient'
import { DoctorPatients } from '../views/doctor/DoctorPatients/DoctorPatients'
import { RedirectHandler } from './RedirectHandler'
import { GetFillSurvey } from '../views/patient/PatientEvents/components/GetFillSurvey'

const AssistantRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userType = useUserType()

  if (userType !== UserType.Assistant) {
    return <Redirect to={`/${userType}/home`} />
  }

  return (
    <Switch>
      <Route path="/assistant/home/appointments">
        <AssistantDashboard />
      </Route>
      <Route path="/assistant/home/surveys">
        <AssistantDashboard />
      </Route>

      <Redirect from="/assistant/home" to="/assistant/home/appointments" />

      <Route path="/assistant/fill-survey/:surveyId">
        <GetFillSurvey />
      </Route>

      <Route
        path={`/assistant/patients/:patientId/:treatmentId/:appointmentId/:action`}
      >
        <DoctorPatient />
      </Route>
      <Route
        path={`/assistant/patients/:patientId/:treatmentId/:appointmentId`}
      >
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients/:patientId/:treatmentId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients/:patientId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients`}>
        <DoctorPatients />
      </Route>
      <Route path="/assistant/profile">
        <AssistantProfile />
      </Route>
      <Route path={`/assistant/doctors`}>
        <AssistantDoctors />
      </Route>
      <Route>
        <RedirectHandler />
      </Route>
    </Switch>
  )
}

export { AssistantRoutes }
