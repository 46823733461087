import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { BetmenList } from '../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../common/components/BetmenList/BetmenListItemCardCell'
import { BetmenPage } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useDoctorOwnAssistantDelete } from './hooks/useDoctorOwnAssistantDelete'
import { useDoctorOwnAssistants } from './hooks/useDoctorOwnAssistants'
import { InviteAssistantModal } from './components/InviteAssistantModal'
import { AddButton } from '../../../common/components/AddButton/AddButton'

export const DoctorAssistants: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation()

  const [isRemovingAssistant, setIsRemovingAssistant] = useState<null | string>(
    null
  )
  const [inviteAssistantModalOpen, setInviteAssistantModalOpen] =
    useState(false)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const {
    assistants,
    refetch: assistantsRefetch,
    loading,
  } = useDoctorOwnAssistants({ searchTerm })

  const [
    removeAssistantFromDoctorMutation,
    { loading: isRemovingAssistantFromDoctor },
  ] = useDoctorOwnAssistantDelete({
    onCompleted: () => {
      setIsRemovingAssistant(null)
      assistantsRefetch()
    },
  })

  return (
    <BetmenPage
      title={t('menu:doctor.assistants')}
      withoutPadding
      headerRightContent={
        <AddButton
          buttonText={t('doctor:addAssistant')}
          handleAdd={() => setInviteAssistantModalOpen(true)}
        />
      }
    >
      <BetmenList
        dataSource={assistants}
        loading={loading}
        gridColumnSizes={[3, 4, 3, 2]}
        filters={
          <BetmenListFilter
            searchPlaceholder={t('doctor:searchAssistant')}
            handleSearch={setSearchTerm}
          />
        }
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell>{t('common:nameTitle')}</BetmenListHeaderCell>
            <BetmenListHeaderCell>{t('common:email')}</BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('common:invite.assistant.registrationNumber')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell actions>
              {t('table:header.actions')}
            </BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {assistants.map((assistant) => (
            <BetmenListItemCard key={assistant.id}>
              <BetmenListItemCardCell>{assistant.name}</BetmenListItemCardCell>
              <BetmenListItemCardCell>{assistant.email}</BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {assistant.registrationNumber}
              </BetmenListItemCardCell>
              <BetmenListActions
                display="flex"
                justifyContent="flex-end"
                remove={() => setIsRemovingAssistant(assistant.id)}
              />
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>
      <InviteAssistantModal
        isOpen={inviteAssistantModalOpen}
        setIsOpen={setInviteAssistantModalOpen}
        ownAssistantRegistrationNumbers={assistants.map(
          (assistant) => assistant.registrationNumber
        )}
        onComplete={assistantsRefetch}
      />

      <Dialog
        open={!!isRemovingAssistant}
        onClose={() => setIsRemovingAssistant(null)}
      >
        <DialogTitle>
          {t('common:invite.assistant.removeAssistant')}
        </DialogTitle>
        <DialogContent sx={{ width: { sm: 400 } }}>
          <DialogContentText>
            {t('common:invite.assistant.confirmRemoval')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsRemovingAssistant(null)}
            variant="outlined"
          >
            {t('common:cancel')}
          </Button>
          <Button
            disabled={isRemovingAssistantFromDoctor}
            onClick={() =>
              isRemovingAssistant &&
              removeAssistantFromDoctorMutation({
                variables: { assistantId: isRemovingAssistant },
              })
            }
            color="error"
            variant="contained"
          >
            {t('common:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </BetmenPage>
  )
}
