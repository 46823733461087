import dayjs from 'dayjs'
import {
  getAssistantProfilesForDoctor,
  getDoctorProfilesForDoctor,
} from '../../../../../../models/graphqlTypes'
import { PatientListItemType } from '../../../../DoctorPatients/types/patientList.types'
import { AssistantTableRowData } from '../../../../DoctorAssistants/types/doctorAssistants.types'

export enum SendSurveyStep {
  SelectToType = 'selectToType',
  SelectRecipient = 'selectRecipient',
  Success = 'success',
}

export enum ResendSurveyStep {
  SelectDate = 'selectDate',
  Success = 'success',
}

export enum SendSurveyToType {
  Patient = 'patient',
  Doctor = 'doctor',
  Assistant = 'assistant',
}

export type DoctorProfile =
  getDoctorProfilesForDoctor['getDoctorProfiles']['profiles'][number]

export type AssistantProfile =
  getAssistantProfilesForDoctor['getAssistantProfiles']['profiles'][number]

export interface SendSurveyForm {
  sendTo: SendSurveyToType
  patients?: PatientListItemType[]
  doctors?: DoctorProfile[]
  assistants?: AssistantTableRowData[]
  aboutPatient?: PatientListItemType
  fillableFromDate: dayjs.Dayjs
  fillableUntilDate: dayjs.Dayjs
}

export interface ResendSurveyForm {
  fillableUntilDate: dayjs.Dayjs
}
