import BarChartIcon from '@mui/icons-material/BarChart'
import { GridSize, IconButton, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BetmenList } from '../../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../../common/components/BetmenList/BetmenListItemCardCell'
import { ChipGroup } from '../../../../common/components/ChipGroup/ChipGroup'
import { PAGE_SIZE } from '../../../../config'
import { useMe } from '../../../../common/hooks/useMe'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  SurveySchemaFilter,
  SurveySchemaStatusEnum,
} from '../../../../models/graphqlTypes'
import { useSurveySchemas } from '../hooks/useSurveySchemas'
import { SurveyListMore } from './Surveys/SurveyListMore'
import { Status } from '../../../../common/components/Status/Status'
import { mapSurveyStatus } from '../utils/survey.mapper'
import { ExtendedSurveySchema } from '../types/surveyStore.types'
import { SurveyResultTab } from './SurveyResult/surveyResult.types'
import { useSurveyPaths } from './SurveyResult/useSurveyPaths'

export const Surveys: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()
  const history = useHistory()
  const { surveyResultBaseUrl } = useSurveyPaths()

  const headerItemsWithGridSize: { [headerItem: string]: GridSize } = {
    title: 3,
    category: 2,
    professions: 3,
    owner: 2,
    status: 1,
    results: 1,
  }

  const [filterInput, setFilterInput] = useState<SurveySchemaFilter>({})

  const {
    loading,
    fetchingMore,
    fetchMoreSchemas,
    refetch,
    surveySchemas,
    hasMoreSchemas,
  } = useSurveySchemas({
    variables: {
      filterData: filterInput,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, filterInput])

  const openQuestions = (id: string) => {
    history.push(`${surveyResultBaseUrl}/${id}/${SurveyResultTab.Questions}`)
  }

  const openResults = (id: string) =>
    history.push(
      `${surveyResultBaseUrl}/${id}/${SurveyResultTab.AggregatedResults}`
    )

  const openSurveyEdit = (id: string) =>
    history.push(`/${me?.userType.toLowerCase()}/survey/${id}`)

  const openSurvey = (surveySchema: ExtendedSurveySchema) => {
    const isEditable =
      surveySchema?.status === SurveySchemaStatusEnum.InActive &&
      surveySchema.user.id === me?.id

    isEditable
      ? openSurveyEdit(surveySchema.id)
      : openQuestions(surveySchema.id)
  }

  const onSurveyEnter = useCallback(() => {
    const offset = surveySchemas.length

    fetchMoreSchemas({
      variables: {
        filterData: filterInput,
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })
  }, [surveySchemas, fetchMoreSchemas, filterInput])

  return (
    <BetmenList
      dataSource={surveySchemas}
      loading={loading && !fetchingMore}
      fetchMore={onSurveyEnter}
      fetchMoreLoading={fetchingMore}
      hasMoreItem={hasMoreSchemas}
      gridColumnSizes={[3, 2, 3, 2, 1, 1]}
      filters={
        <BetmenListFilter
          searchPlaceholder={t(`survey:surveys.search`)}
          searchTerm={filterInput.searchTerm || ''}
          handleSearch={(searchTerm) =>
            setFilterInput((prevInput) => {
              return { ...prevInput, searchTerm }
            })
          }
        ></BetmenListFilter>
      }
      header={
        <BetmenListHeader>
          {Object.keys(headerItemsWithGridSize).map(
            (headerItem: string, i: number) => (
              <BetmenListHeaderCell key={`header_${i}`}>
                {t(`survey:surveys.${headerItem}`)}
              </BetmenListHeaderCell>
            )
          )}
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {surveySchemas.map((surveySchema) => (
          <BetmenListItemCard
            click={() => openSurvey(surveySchema)}
            key={'wp+' + surveySchema.id}
          >
            <BetmenListItemCardCell>
              {surveySchema.title}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <ChipGroup
                values={surveySchema.categories.map((category) =>
                  t(`survey:survey.type.${category.toLowerCase()}`)
                )}
                limitTags={2}
              />
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <ChipGroup
                values={surveySchema.professions.map(
                  (profession) => profession.name
                )}
                limitTags={2}
              />
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              {surveySchema.isGlobal
                ? t('survey:survey.isGlobal')
                : surveySchema.isOwnSurvey
                  ? `${t('common:formattedNameFull', {
                      title: me?.profile?.title,
                      firstName: me?.profile?.firstName,
                      lastName: me?.profile?.lastName,
                    })} (${t('survey:question.own')})`
                  : t('common:formattedNameFull', {
                      title: surveySchema.user.doctorProfile?.title,
                      firstName: surveySchema.user.doctorProfile?.firstName,
                      lastName: surveySchema.user.doctorProfile?.lastName,
                    })}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <Status status={mapSurveyStatus(surveySchema.status)} />
            </BetmenListItemCardCell>
            <BetmenListActions
              more={
                <SurveyListMore
                  surveySchema={surveySchema}
                  refetchSurveys={refetch}
                  showEditButton={true}
                />
              }
            >
              <Tooltip
                title={t('survey:survey.results') as string}
                placement="top"
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    openResults(surveySchema.id)
                  }}
                >
                  <BarChartIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </BetmenListActions>
          </BetmenListItemCard>
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}
