import React from 'react'
import { Box } from '@mui/material'

import { PageWithScrollableCard } from '../common/layout/PageWithScrollableCard'
import { useTranslation } from '../common/hooks'

const About: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <PageWithScrollableCard
        pageTitle={t('about:pageTitle')}
        markdownTextInCard={t('about:section.info.content')}
      />
    </Box>
  )
}

export { About }
