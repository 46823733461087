import React, { useState } from 'react'
import { useTranslation, useUserType } from '../../hooks'
import { SurveyFilterForDoctor, UserType } from '../../../models/graphqlTypes'
import { DoctorReceivedSurveysOnDashboard } from './DoctorReceivedSurveysOnDashboard'
import { Tab, Tabs } from '@mui/material'
import { DoctorSentSurveysOnDashboard } from './DoctorSentSurveysOnDashboard'

interface Props {
  filterInput: SurveyFilterForDoctor
  setFilterInput: (filterInput: SurveyFilterForDoctor) => void
  isFilterPanelOpen: boolean
  handleFilterOpen: () => void
}
export const DoctorSurveysOnDashboard: React.FC<
  React.PropsWithChildren<Props>
> = ({ setFilterInput, ...props }) => {
  const { t } = useTranslation()
  const userType = useUserType()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const handleSearch = (searchInput: string) => {
    setFilterInput({
      ...props.filterInput,
      searchTerm: searchInput,
    })
  }

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        textColor="inherit"
        scrollButtons="auto"
        variant="scrollable"
        onChange={(_, tabIndex) => setSelectedTabIndex(tabIndex)}
        orientation="horizontal"
        sx={{ marginLeft: 2 }}
      >
        <Tab key={0} label={t('home:doctor.surveys.received')} />
        {userType === UserType.Doctor && (
          <Tab key={1} label={t('home:doctor.surveys.sent')} />
        )}
      </Tabs>
      {selectedTabIndex === 0 && (
        <DoctorReceivedSurveysOnDashboard
          handleSearch={handleSearch}
          {...props}
        />
      )}
      {selectedTabIndex === 1 && userType === UserType.Doctor && (
        <DoctorSentSurveysOnDashboard handleSearch={handleSearch} {...props} />
      )}
    </>
  )
}
