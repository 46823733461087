import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import React, { useState } from 'react'
import { SelectedChip } from '../../../../../common/components/SelectedChip/SelectedChip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FilterPopover } from '../../../../../common/components/Filters/FilterPopover'
import { ClearFiltersButton } from '../../../../../common/components/Filters/ClearFiltersButton'
import { useTranslation } from '../../../../../common/hooks'
import { Controller, useFormContext } from 'react-hook-form'
import { SurveyDoneStatusEnum } from '../../../../../models/graphqlTypes'
import { mapFillOutStateFilter } from '../../utils/survey.mapper'

export const FillOutStateFilter: React.FC = () => {
  const { t } = useTranslation('survey')

  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)

  const { watch, control, setValue } = useFormContext()

  const statusesFilter = watch('statusesFilter')
  const selectedStatuses = mapFillOutStateFilter(statusesFilter)

  return (
    <Stack direction={'row'} flex={1} justifyContent={'flex-end'}>
      <SelectedChip
        selected={!!selectedStatuses?.length}
        onClick={(e) => setFilterAnchor(e.currentTarget)}
        onDelete={() => {
          // we need this onDelete function to see the deleteIcon
        }}
        deleteIcon={<KeyboardArrowDownIcon />}
        label={t('surveys.status')}
      />

      {filterAnchor && (
        <FilterPopover
          filterAnchorState={[filterAnchor, setFilterAnchor]}
          open={!!filterAnchor}
        >
          <ClearFiltersButton
            onClear={() => setValue('statusesFilter', {})}
            disabled={!selectedStatuses?.length}
          />
          <hr />

          <Stack px={2}>
            {[
              SurveyDoneStatusEnum.FINISHED,
              SurveyDoneStatusEnum.TODO,
              SurveyDoneStatusEnum.EXPIRED,
            ].map((status) => (
              <Controller
                key={status}
                render={({ field }) => (
                  <FormControlLabel
                    label={t(`status.${status.toLowerCase()}`)}
                    control={
                      <Checkbox
                        {...field}
                        checked={!!watch(`statusesFilter.${status}`)}
                      />
                    }
                  />
                )}
                name={`statusesFilter.${status}`}
                control={control}
              />
            ))}
          </Stack>
        </FilterPopover>
      )}
    </Stack>
  )
}
