import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { TreatmentGroupedAppointmentsComponentViewModel } from '../TreatmentGroupedAppointments'
import {
  AddAppointmentModalParams,
  RouteParams,
} from '../../../types/treatmentPanel.types'
import {
  getFilteredAppointments,
  getGroupedAppointmentsAndSurveys,
} from '../../../utils/appointment.util'
import { useSelectedLanguage } from '../../../../../../common/hooks/useSelectedLanguage'
import dayjs from 'dayjs'
import { useArrowProps } from './useArrowProps'
import { usePermissions } from '../../../../../../common/hooks/usePermissions'
import { TreatmentStatus } from '../../../../../../models/graphqlTypes'
import { getParentsIds } from '../utils/treatmentPanel.helper'
import { useAppointmentOperations } from './useAppointmentOperations'
import { useSurveyOperations } from './useSurveyOperations'
import { AddSurveyModalParams } from '../../Survey/AddSurveyModal'
import { useParams } from 'react-router-dom'
import { Appointment, Survey, Treatment } from '../../../types/treatments.types'

interface UseTreatmentGroupedAppointmentsComponentViewModelProps {
  treatment: Treatment
  setSurvey: React.Dispatch<React.SetStateAction<Survey | null>>
  filterValue: string
  showPast: boolean
  setShowPast: Dispatch<SetStateAction<boolean>>
  hoveredAppointmentId: string | null
  setHoveredAppointmentId: Dispatch<SetStateAction<string | null>>
  linkingDisabledIds: string[]
  setLinkingDisabledIds: Dispatch<SetStateAction<string[]>>
  linkingAppointments: Appointment[]
  setLinkingAppointments: Dispatch<SetStateAction<Appointment[]>>
  firstRef: React.RefObject<HTMLElement | null>
  clearFilterValue: () => void
  toggleAddAppointmentModal: React.Dispatch<
    React.SetStateAction<AddAppointmentModalParams>
  >
  toggleAddSurveyModal: React.Dispatch<
    React.SetStateAction<AddSurveyModalParams>
  >
}

export const useTreatmentGroupedAppointmentsComponentViewModel = ({
  treatment,
  setSurvey,
  filterValue,
  showPast,
  setShowPast,
  hoveredAppointmentId,
  setHoveredAppointmentId,
  linkingDisabledIds,
  setLinkingDisabledIds,
  linkingAppointments,
  setLinkingAppointments,
  firstRef,
  clearFilterValue,
  toggleAddAppointmentModal,
  toggleAddSurveyModal,
}: UseTreatmentGroupedAppointmentsComponentViewModelProps): TreatmentGroupedAppointmentsComponentViewModel => {
  const selectedLanguage = useSelectedLanguage()
  const todayStart = dayjs().startOf('day').toISOString()

  const { treatmentId } = useParams<RouteParams>()

  const { isOwnTreatment, isAssistantOfTreatment } = usePermissions(
    treatment?.doctor.id
  )

  const appointments = useMemo(() => treatment.appointments || [], [treatment])
  const surveys = useMemo(() => treatment.surveys || [], [treatment])

  const isDraft = treatment?.status === TreatmentStatus.Draft
  const isDeleted = treatment?.status === TreatmentStatus.Cancelled

  useEffect(() => {
    // if treatment is deleted, show past appointments by default
    if (isDeleted) {
      setShowPast(true)
    }

    // if treatment has only past appointments, show past appointments by default
    const futureAppointments = appointments.filter(
      (a) => todayStart < a.proposedDate
    )
    const hasAppointments = appointments.length > 0
    const hasFutureAppointments = futureAppointments.length > 0
    if (hasAppointments && !hasFutureAppointments) {
      setShowPast(true)
    }
  }, [appointments, setShowPast, todayStart, isDeleted])

  const {
    groupedAppointmentsAndSurveys,
    visibleAppointments,
    visibleSurveys,
    futureAppointments,
    filteredAppointment,
    isAllOrderedAppointmentsPast,
    futureSurveys,
  } = useMemo(() => {
    const dateSortedOrderedSurveys = [...surveys].sort((a, b) =>
      a.fillableFrom > b.fillableFrom ? 1 : -1
    )

    const filteredAppointment = getFilteredAppointments(
      appointments,
      treatment,
      selectedLanguage,
      filterValue
    )

    const futureAppointments = filteredAppointment.filter(
      (a) => todayStart <= a.proposedDate
    )

    const futureSurveys = dateSortedOrderedSurveys.filter(
      (s) => todayStart <= s.fillableFrom
    )

    const visibleAppointments = showPast
      ? filteredAppointment
      : futureAppointments

    const visibleSurveys = showPast ? dateSortedOrderedSurveys : futureSurveys

    const groupedAppointmentsAndSurveys = getGroupedAppointmentsAndSurveys(
      filteredAppointment,
      dateSortedOrderedSurveys
    )

    const isAllOrderedAppointmentsPast =
      filteredAppointment.length &&
      !filteredAppointment.find(
        (appointment) =>
          dayjs().startOf('day') <= dayjs(appointment.proposedDate)
      )

    return {
      groupedAppointmentsAndSurveys,
      visibleAppointments,
      visibleSurveys,
      futureAppointments,
      filteredAppointment,
      isAllOrderedAppointmentsPast,
      futureSurveys,
    }
  }, [
    appointments,
    filterValue,
    selectedLanguage,
    showPast,
    todayStart,
    treatment,
    surveys,
  ])

  const handleLink = useCallback(
    (appointment: Appointment) => {
      const isExists = linkingAppointments.some(
        (item) => item.id === appointment.id
      )
      if (isExists) {
        setLinkingDisabledIds([])
        setLinkingAppointments([])
      } else {
        const allParents = getParentsIds(
          treatment?.appointments || [],
          appointment.id
        )
        const directParents = getParentsIds(
          treatment?.appointments || [],
          appointment.id,
          [],
          false
        )
        const disabledIds = allParents.filter(
          (id) => !directParents.includes(id)
        )
        setLinkingDisabledIds(disabledIds)
        setLinkingAppointments((prevState) => [...prevState, appointment])
      }
    },
    [
      linkingAppointments,
      setLinkingAppointments,
      setLinkingDisabledIds,
      treatment?.appointments,
    ]
  )

  const { arrowProps, widthOfArrows } = useArrowProps({
    filterValue,
    visibleAppointments,
    hoveredAppointmentId,
    treatment,
  })

  const { openAppointment, editAppointment, cloneAppointment } =
    useAppointmentOperations({ clearFilterValue, toggleAddAppointmentModal })

  const { onOpenSurvey, onEditSurvey } = useSurveyOperations({
    setSurvey,
    treatmentId,
    patientUserId: treatment.patient.user?.id ?? '',
    toggleAddSurveyModal,
    clearFilterValue,
  })

  return {
    filterValue,
    filteredAppointment,
    groupedAppointmentsAndSurveys,
    showPast,
    widthOfArrows,
    arrowProps,
    visibleAppointments,
    visibleSurveys,
    linkingDisabledIds,
    isAllOrderedAppointmentsPast,
    onClickAppointment: openAppointment,
    onClickSurvey: onOpenSurvey,
    editSurvey: onEditSurvey,
    treatmentItemViewModel: {
      firstRef,
      isDraft,
      isAssistantOfTreatment,
      isOwnTreatment,
      linking: linkingAppointments[0],
      nextAppointment: futureAppointments[0],
      firstFutureAppointment: futureAppointments[1],
      nextSurvey: futureSurveys[0],
      firstFutureSurvey: futureSurveys[1],
      cloneAppointment,
      editAppointment,
      onHover: setHoveredAppointmentId,
      onLink: handleLink,
      setShowPast,
    },
  }
}
