import { Reference, TypePolicies } from '@apollo/client'

const mergeForPagination = (
  existing: Reference[],
  incoming: Reference[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any
) => {
  const merged = existing ? existing.slice(0) : []
  if (variables?.paginationInput) {
    const { offset = 0 } = variables.paginationInput
    for (let i = 0; i < incoming.length; ++i) {
      merged[offset + i] = incoming[i]
    }
  } else {
    merged.push(...incoming)
  }
  return merged
}

const typePolicies: TypePolicies = {
  Appointment: {
    fields: {
      appointmentInfo: {
        merge(existing, incoming) {
          return { ...existing, ...incoming }
        },
      },
    },
  },
  PatientProfileList: {
    fields: {
      patients: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  PatientProfile: {
    fields: {
      user: {
        merge(existing, incoming) {
          return { ...existing, ...incoming }
        },
      },
      treatments: {
        merge(existing, incoming) {
          return incoming
        },
      },
    },
  },
  DoctorProfile: {
    fields: {
      user: {
        merge(existing, incoming) {
          return { ...existing, ...incoming }
        },
      },
      institutionToDoctor: {
        merge(existing, incoming) {
          return incoming || existing
        },
      },
      professions: {
        merge(existing, incoming) {
          return incoming || existing
        },
      },
    },
  },
  AppointmentInfo: {
    fields: {
      professions: {
        merge(_existing, incoming: Reference[] = []) {
          return [...(incoming ? [...incoming] : [])]
        },
      },
    },
  },
  AppointmentList: {
    fields: {
      appointments: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  SurveyQuestionList: {
    fields: {
      surveyQuestions: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  SurveySchemaList: {
    fields: {
      surveySchemas: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  IndividualResults: {
    fields: {
      surveys: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  SurveyList: {
    fields: {
      surveys: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  InstitutionList: {
    fields: {
      institutions: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  TreatmentSchemaList: {
    fields: {
      treatmentSchemas: {
        merge(existing: Reference[], incoming: Reference[], { variables }) {
          return mergeForPagination(existing, incoming, variables)
        },
      },
    },
  },
  Query: {
    fields: {
      getPartnerMessages: {
        keyArgs: ['partnerUserId'],

        merge(
          existing: Reference[] = [],
          incoming: Reference[],
          { readField }
        ) {
          const isSameMessage = (
            existingMessageRef: Reference,
            incomingMessageRef: Reference
          ): boolean => {
            const existingMessageId = readField('id', existingMessageRef)
            const incomingMessageId = readField('id', incomingMessageRef)

            return existingMessageId === incomingMessageId
          }

          return [
            ...existing.filter(
              (existingMessageRef) =>
                !incoming.find((incomingMessageRef) =>
                  isSameMessage(existingMessageRef, incomingMessageRef)
                )
            ),
            ...incoming,
          ]
        },
      },
      listAppointmentInfos: {
        merge(_existing, incoming: Reference[]) {
          return [...(incoming ? [...incoming] : [])]
        },
      },
      getDashboardAppointments: {
        keyArgs: ['startDate', 'endDate'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getTreatmentSchemas: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      institutionAdminProfiles: {
        merge(_existing, incoming: Reference[]) {
          return incoming
        },
      },
      getSurveyQuestions: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSurveySchemas: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getIndividualSurveyResults: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSurveys: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSurveysForPatient: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getOwnSurveys: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getReceivedSurveysForAssistant: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getReceivedSurveysForDoctor: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSentSurveysForAssistant: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSentSurveysForDoctor: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getSurveysForSchemaId: {
        keyArgs: ['surveys'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      getAppointments: {
        keyArgs: ['appointments'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      listInstitutions: {
        keyArgs: ['title'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      listPatientsForDoctor: {
        keyArgs: ['createdAt', 'id'],
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
}
export default typePolicies
