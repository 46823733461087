import {
  appointmentInfoChore,
  AppointmentStatus,
  BnoCodeChore,
  institutionChore,
  PatientFilter,
} from '../../../../models/graphqlTypes'
import { AssistantDoctor } from './patientList.types'

export type PatientListFilter = PatientFilter & { doctorIds?: string[] }

export type PatientListFiltersForm = Pick<
  PatientFilter,
  'gender' | 'cities' | 'appointmentDateRange'
> & {
  bnoCode: { includes: BnoCodeChore[]; excludes: BnoCodeChore[] }
  appointments: appointmentInfoChore[]
  age: number[]
  appointmentStatuses: Partial<Record<AppointmentStatus, boolean>>
  institutions: institutionChore[]
  doctors?: AssistantDoctor[]
}

export enum UsedAppointmentStatus {
  BetmenBooked = AppointmentStatus.BetmenBooked,
  Expired = AppointmentStatus.Expired,
  Happened = AppointmentStatus.Happened,
  Proposed = AppointmentStatus.Proposed,
}
