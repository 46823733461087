import { gql } from '@apollo/client'
import {
  FRAGMENT_APPOINTMENT_CHORE,
  FRAGMENT_APPOINTMENT_INFO,
  FRAGMENT_DEPENDENCY_CHORE,
  FRAGMENT_DOCTOR_PROFILE_MINIMAL_DATA,
  FRAGMENT_INSTITUTION_CHORE,
  FRAGMENT_MULTILANGTEXT,
  FRAGMENT_ROOM_CHORE,
} from './fragments'

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($appointmentInput: AppointmentInput!) {
    createAppointment(appointmentInput: $appointmentInput) {
      ...appointmentChore
      duration
    }
  }
  ${FRAGMENT_APPOINTMENT_CHORE}
`

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($updateInput: AppointmentUpdateInput!) {
    updateAppointment(updateInput: $updateInput) {
      ... on Appointment {
        id
        proposedDate
        institution {
          id
        }
        room {
          id
        }
        status
      }
      ... on CalculatedSchedule {
        schedule {
          date {
            end
            start
          }
          isModified
          oldDate {
            end
            start
          }
          id
          appointmentInfo {
            id
          }
          doctorTitle {
            ...multiLangText
          }
        }
        brokenDependingFrom {
          id
          type
          distance
          from {
            info {
              doctorTitle {
                ...multiLangText
              }
            }
          }
        }
        brokenDependants {
          id
          type
          distance
          to: appointment {
            info {
              doctorTitle {
                ...multiLangText
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
`

export const UPDATE_APPOINTMENT_WITH_DEPS = gql`
  mutation UpdateAppointmentWithDeps(
    $updateInput: AppointmentUpdateInput!
    $reschedule: Boolean!
  ) {
    updateAppointmentWithDeps(
      updateInput: $updateInput
      reschedule: $reschedule
    ) {
      createdAt
    }
  }
`

export const UPDATE_APPOINTMENT_GOODTOKNOW = gql`
  mutation updateAppointmentGoodToKnow(
    $appointmentId: String!
    $updateGoodToKnowInput: AppointmentUpdateGoodToKnowInput!
  ) {
    updateAppointmentGoodToKnow(
      appointmentId: $appointmentId
      updateGoodToKnowInput: $updateGoodToKnowInput
    ) {
      id
    }
  }
`

export const UPDATE_APPOINTMENT_OENO = gql`
  mutation updateAppointmentOeno($appointmentId: String!, $oeno: String!) {
    updateAppointmentOeno(appointmentId: $appointmentId, oeno: $oeno) {
      id
    }
  }
`

export const DELETE_APPOINTMENT = gql`
  mutation deleteAppointment($appointmentId: String!) {
    deleteAppointment(appointmentId: $appointmentId) {
      id
      eesztBookingId
    }
  }
`

export const DELETE_APPOINTMENTS = gql`
  mutation deleteAppointments($appointmentIds: [String!]!) {
    deleteAppointments(appointmentIds: $appointmentIds) {
      id
    }
  }
`

export const TRIGGER_EXPIRE_PAST_APPOINTMENTS = gql`
  query triggerExpirePastAppointments {
    triggerExpirePastAppointments {
      id
    }
  }
`

export const CREATE_APPOINTMENT_DEPENDENCY = gql`
  mutation createAppointmentDependency(
    $createAppointmentDependencyInput: CreateAppointmentDependencyInput!
  ) {
    createAppointmentDependency(
      createAppointmentDependencyInput: $createAppointmentDependencyInput
    ) {
      ...dependencyChore
    }
  }
  ${FRAGMENT_DEPENDENCY_CHORE}
`
export const UPDATE_APPOINTMENT_DEPENDENCY = gql`
  mutation updateAppointmentDependency(
    $updateAppointmentDependencyInput: UpdateAppointmentDependencyInput!
  ) {
    updateAppointmentDependency(
      updateAppointmentDependencyInput: $updateAppointmentDependencyInput
    ) {
      ...dependencyChore
    }
  }
  ${FRAGMENT_DEPENDENCY_CHORE}
`
export const DELETE_APPOINTMENT_DEPENDENCY = gql`
  mutation deleteAppointmentDependency($dependencyId: String!) {
    deleteAppointmentDependency(dependencyId: $dependencyId) {
      id
    }
  }
`

export const LIST_APPOINTMENT_INFO = gql`
  query listAppointmentInfos($treatmentSchemaId: String) {
    listAppointmentInfos(treatmentSchemaId: $treatmentSchemaId) {
      ...appointmentInfoChore
    }
  }
  ${FRAGMENT_APPOINTMENT_INFO}
`

export const CREATE_APPOINTMENT_INFO = gql`
  mutation createAppointmentInfo(
    $createAppointmentInfoInput: CreateAppointmentInfoInput!
  ) {
    createAppointmentInfo(
      createAppointmentInfoInput: $createAppointmentInfoInput
    ) {
      ...appointmentInfoChore
    }
  }
  ${FRAGMENT_APPOINTMENT_INFO}
`

export const UPDATE_APPOINTMENT_INFO = gql`
  mutation updateAppointmentInfo(
    $appointmentInfoInput: UpdateAppointmentInfoInput!
  ) {
    updateAppointmentInfo(appointmentInfoInput: $appointmentInfoInput) {
      ...appointmentInfoChore
    }
  }
  ${FRAGMENT_APPOINTMENT_INFO}
`

export const UPDATE_SCHEDULE_APPOINTMENT_INFO = gql`
  mutation updateScheduleAppointmentInfo(
    $scheduleId: String!
    $appointmentInfoInput: UpdateScheduleAppointmentInfoInput!
  ) {
    updateScheduleAppointmentInfo(
      scheduleId: $scheduleId
      appointmentInfoInput: $appointmentInfoInput
    ) {
      ...appointmentInfoChore
    }
  }
  ${FRAGMENT_APPOINTMENT_INFO}
`

export const DELETE_APPOINTMENT_INFO = gql`
  mutation deleteAppointmentInfo(
    $appointmentInfoInput: DeleteAppointmentInfoInput!
  ) {
    deleteAppointmentInfo(appointmentInfoInput: $appointmentInfoInput) {
      ...appointmentInfoChore
    }
  }
  ${FRAGMENT_APPOINTMENT_INFO}
`

export const GET_APPOINTMENT_ICS = gql`
  query getAppointmentIcs($appointmentId: String!) {
    getAppointment(appointmentId: $appointmentId) {
      id
      ics
    }
  }
`

export const GET_PATIENT_APPOINTMENT = gql`
  query getPatientAppointment($appointmentId: String!) {
    getAppointment(appointmentId: $appointmentId) {
      id
      proposedDate
      status
      ratingStars
      ratingText
      updatedAt
      timeFrom
      timeTo
      isBetmenBooked
      eesztBookingId
      info {
        patientTitle {
          ...multiLangText
        }
        goodToKnow {
          ...multiLangText
        }
      }
      institution {
        ...institutionChore
      }
      room {
        ...roomChore
      }
      treatmentSchema {
        title {
          ...multiLangText
        }
        description {
          ...multiLangText
        }
      }
      room {
        id
      }
      treatment {
        id
        status
        firstAndLastDates {
          firstAppointmentDate
          lastAppointmentDate
        }
        description {
          ...multiLangText
        }
        title {
          ...multiLangText
        }
        doctor {
          id
          title
          firstName
          lastName
        }
      }
      doctorProfile {
        ...doctorProfileMinimalForPatients
      }
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_ROOM_CHORE}
  ${FRAGMENT_INSTITUTION_CHORE}
  ${FRAGMENT_DOCTOR_PROFILE_MINIMAL_DATA}
`
