import React, { useCallback } from 'react'
import { useDoctorPatientSurveysData } from '../../Treatments/hooks/useDoctorPatientSurveysData'
import {
  BetmenList,
  BetmenListBody,
  BetmenListHeader,
  BetmenListHeaderCell,
  BetmenListItemCard,
  BetmenListItemCardCell,
} from '../../../../common/components'
import { ChipGroup } from '../../../../common/components/ChipGroup/ChipGroup'
import { SurveyStatus } from './PatientSurveys/SurveyStatus'
import { SurveyActionButton } from './PatientSurveys/SurveyActionButton'
import { useParams } from 'react-router-dom'
import { PAGE_SIZE } from '../../../../config'
import { useTranslation } from '../../../../common/hooks'

type RouteParams = {
  patientId?: string
}

export const PatientSurveys: React.FC = () => {
  const { patientId: currentPatientId } = useParams<RouteParams>()
  const patientId = currentPatientId || ''

  const { t } = useTranslation()
  const {
    patientSurveys,
    loading,
    hasMoreSurveys,
    fetchMoreSurveys,
    fetchingMore,
  } = useDoctorPatientSurveysData({
    variables: {
      patient: { patientId },
      paginationInput: { limit: PAGE_SIZE, offset: 0 },
    },
  })

  const onSurveyEnter = useCallback(() => {
    const offset = patientSurveys.length
    fetchMoreSurveys({
      variables: {
        patient: { patientId },
        paginationInput: { limit: PAGE_SIZE, offset },
      },
    })
  }, [patientSurveys.length, fetchMoreSurveys, patientId])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      <BetmenList
        extraHeaderStyle={{ backgroundColor: 'white' }}
        dataSource={patientSurveys}
        loading={loading}
        hasMoreItem={hasMoreSurveys}
        fetchMore={onSurveyEnter}
        fetchMoreLoading={fetchingMore}
        gridColumnSizes={[4, 3, 2, 2]}
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell>
              {t('patients:survey.header.title')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('patients:survey.header.category')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('patients:survey.header.sender')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('patients:survey.header.status')}
            </BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {patientSurveys.map((survey) => (
            <BetmenListItemCard key={survey.id}>
              <BetmenListItemCardCell>
                {survey.surveySchema.title}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <ChipGroup
                  values={survey.surveySchema.categories.map((category) =>
                    t(`survey:survey.type.${category.toLowerCase()}`)
                  )}
                  limitTags={3}
                />
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {t('common:formattedNameFull', {
                  title: survey.doctor?.title,
                  firstName: survey.doctor?.firstName,
                  lastName: survey.doctor?.lastName,
                })}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <SurveyStatus survey={survey} />
                <SurveyActionButton survey={survey} />
              </BetmenListItemCardCell>
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>
    </div>
  )
}
