import { useMutation, useQuery } from '@apollo/client'
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { apolloClient } from '../../../ApolloProvider'
import { useTranslation } from '../../hooks/helper/useTranslation'
import {
  getNotifications,
  markAllNotificationAsRead,
  markNotificationAsRead,
  markNotificationAsReadVariables,
  NotificationTypes,
} from '../../../models/graphqlTypes'
import { GET_DOCTOR_PATIENT_TREATMENTS } from '../../../operations/doctorPatientOperations'
import { GET_DOCTOR_OWN_PATIENTS } from '../../../operations/doctorProfileOperations'
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_READ,
  MARK_NOTIFICATION_READ,
  NEW_NOTIFICATION,
} from '../../../operations/notificationOperations'
import {
  GET_TREATMENT,
  PATIENT_HOME_DATA,
} from '../../../operations/treatmentOperations'
import { compareDates } from '../../../utils/dates'
import { BellIcon } from '../../icons/BellIcon'
import { Loading } from '../Loading'
import { NotificationItem } from './NotificationItem'
import { DASHBOARD_APPOINTMENTS } from '../../../operations/dashboardOperations'

const NotificationPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [open, toggleOpen] = useState(false)

  const anchorEl = useRef(null)

  const { data, subscribeToMore, refetch, loading } =
    useQuery<getNotifications>(GET_NOTIFICATIONS, {
      notifyOnNetworkStatusChange: true,
    })

  useEffect(() => {
    subscribeToMore({
      document: NEW_NOTIFICATION,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateQuery: (prev, { subscriptionData }: { subscriptionData: any }) => {
        if (!subscriptionData.data) {
          return prev
        }
        const { newNotification } = subscriptionData.data
        newNotification.data = JSON.parse(newNotification.data)

        const shouldRefetchPatientData = [
          NotificationTypes.AppointmentGoodToKnowChanged,
          NotificationTypes.AppointmentLocationChanged,
          NotificationTypes.AppointmentStatusChanged,
          NotificationTypes.AppointmentTimeChanged,
          NotificationTypes.CancelledTreatment,
          NotificationTypes.DoctorDeclinedAppointment,
          NotificationTypes.NewAppointment,
          NotificationTypes.NewDoctorAssignedToAppointment,
          NotificationTypes.NewHealthIssue,
          NotificationTypes.NewTreatment,
        ].includes(newNotification.type)
        const shouldRefetchDoctorData = [
          NotificationTypes.DoctorReceivedNewPatient,
          NotificationTypes.NewAppointmentAssignedToDoctor,
        ].includes(newNotification.type)

        if (shouldRefetchPatientData) {
          apolloClient.refetchQueries({ include: [PATIENT_HOME_DATA] })
        }
        if (shouldRefetchDoctorData) {
          const { treatmentId, patientId } = newNotification?.data || {}

          apolloClient.refetchQueries({
            include: [GET_DOCTOR_OWN_PATIENTS, DASHBOARD_APPOINTMENTS],
          })

          if (treatmentId) {
            apolloClient.refetchQueries({ include: [GET_TREATMENT] })
          }
          if (patientId) {
            apolloClient.refetchQueries({
              include: [GET_DOCTOR_PATIENT_TREATMENTS],
            })
          }
        }

        return {
          getNotifications: [newNotification, ...prev.getNotifications],
        }
      },
    })
  }, [subscribeToMore])

  const [markAsRead] = useMutation<
    markNotificationAsRead,
    markNotificationAsReadVariables
  >(MARK_NOTIFICATION_READ)

  const [markAllAsRead] = useMutation<markAllNotificationAsRead>(
    MARK_ALL_NOTIFICATION_READ,
    {
      onCompleted: () => refetch(),
    }
  )

  const notifications = useMemo(() => {
    const notifs = data?.getNotifications
    if (!notifs) {
      return []
    }

    return notifs.filter((notif) =>
      compareDates(new Date(), notif.sentAt, {
        isAfterOrSame: true,
      })
    )
  }, [data?.getNotifications])

  const unreadNotificationsCount = notifications.filter(
    (notification) => !notification.isRead
  ).length

  const handleNotificationClick = useCallback(
    (notificationId: string) => {
      toggleOpen(false)
      markAsRead({ variables: { notificationId } })
    },
    [markAsRead]
  )

  return (
    <ClickAwayListener onClickAway={() => toggleOpen(false)}>
      {/** The listener accepts only one child and it cant be a react fragment  */}
      <Box>
        <Box color="common.white">
          <IconButton
            data-cy="Notification-Bell-Button"
            ref={anchorEl}
            aria-label={`show ${unreadNotificationsCount} new notifications`}
            onClick={() => toggleOpen(!open)}
            color="inherit"
            size="large"
          >
            <Badge badgeContent={unreadNotificationsCount || 0} color="error">
              <BellIcon width={16} height={20} color="inherit" />
            </Badge>
          </IconButton>
        </Box>

        <Popper
          style={{ zIndex: 100 }}
          open={open}
          anchorEl={anchorEl?.current}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={2}
                sx={{
                  maxWidth: 550,
                  maxHeight: '80vh',
                  display: 'flex',
                  flexDirection: 'column',
                  border: 1,
                  borderColor: `${theme.palette.common.black}34`,
                }}
              >
                <Box
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {t('notification:notifications')}
                  </Typography>
                  <Button
                    onClick={() => markAllAsRead()}
                    disabled={!unreadNotificationsCount}
                    variant="text"
                    color="primary"
                  >
                    {t('notification:MarkAllAsRead')}
                  </Button>
                </Box>
                <Divider />

                {loading && (
                  <>
                    <Box
                      p={2}
                      height={200}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Loading />
                    </Box>
                  </>
                )}
                {!loading && !notifications?.length && (
                  <>
                    <Box p={2}>
                      <Typography variant="body1">
                        {t('notification:noNewMessages')}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box style={{ overflowY: 'auto' }}>
                  {notifications.map((notification) => (
                    <Box key={notification.id}>
                      <NotificationItem
                        notification={notification}
                        handleClick={handleNotificationClick}
                      />
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export { NotificationPanel }
