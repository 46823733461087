import { Button, ButtonProps } from '@mui/material'
import isFunction from 'lodash/isFunction'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { useSurveySchemaUnsubscribe } from '../../hooks/useSurveySchemaUnsubscribe'
import { useMe } from '../../../../../common/hooks/useMe'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { useTranslation } from '../../../../../common/hooks'

interface Props extends ButtonProps {
  surveySchema: SurveySchemaWithoutUser
  refetchSurveys?: () => void
}

export const UnsubscribeSurveyButton: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveySchema, refetchSurveys, ...buttonProps }) => {
  const { t } = useTranslation('survey')
  const classes = useStylesForSurveyActionButtons()
  const history = useHistory()
  const userType = useUserType()

  const { data: { me } = {} } = useMe()
  const { setSuccessToast, setErrorToast } = useNotifications()

  const [confirmUnsubscribe, setConfirmUnsubscribe] =
    useState<ConfirmDialogValue>(false)

  const canUnsubscribe = surveySchema.sharedWithUsers.some(
    (user) => user.id === me?.id
  )

  const [unsubscribeSurvey] = useSurveySchemaUnsubscribe()

  const onUnsubscribe = async () =>
    await unsubscribeSurvey({
      variables: {
        surveySchemaId: surveySchema.id,
      },
      onCompleted: () => {
        history.push(`/${userType?.toLowerCase()}/surveys`)
        setSuccessToast('successUnsubscribeSurvey')
        isFunction(refetchSurveys) && refetchSurveys()
      },
      onError: (e) => setErrorToast(e),
    })

  return (
    <>
      {canUnsubscribe && (
        <Button
          classes={{ textPrimary: classes.textPrimary }}
          variant="text"
          startIcon={<UnsubscribeIcon />}
          onClick={() => setConfirmUnsubscribe(true)}
          {...buttonProps}
        >
          {t('buttons.unsubscribe')}
        </Button>
      )}

      {confirmUnsubscribe && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmUnsubscribe, setConfirmUnsubscribe]}
          title={t('unsubscribe.confirm.title')}
          text={t('unsubscribe.confirm.text')}
          onAccept={onUnsubscribe}
        />
      )}
    </>
  )
}
