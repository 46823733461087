import groupBy from 'lodash/groupBy'
import {
  SurveyQuestionToSurveySchemaInput,
  SurveyQuestion2SurveySchemaDataInput,
  NextSectionInformation,
  SurveySchemaStatusEnum,
  SurveyDoneStatusEnum,
} from '../../../../models/graphqlTypes'
import { Section } from '../components/EditSurvey/editSurvey.types'
import {
  AspectInForm,
  ChoiceInForm,
} from './../components/Questions/questions.types'
import {
  getDefaultValues,
  getQuestionData,
  getValuesToSave,
} from './question.util'
import { StatusEnum } from '../../../../common/components/Status/Status'
import { SurveySchemaQuestion } from '../types/surveyStore.types'

const isNotNullOrUndefined = <T>(input: null | undefined | T): input is T => {
  return input != null
}

const mapNextSectionInfosToSurveyQuestion2SurveySchemaDataInput = (
  choices: ChoiceInForm[]
): SurveyQuestion2SurveySchemaDataInput | null => {
  if (!choices.some((choice) => !!choice.nextSectionOrder)) {
    return null
  }

  const nextSectionInformation = choices
    .map((choice, i) =>
      choice.nextSectionOrder
        ? {
            choiceId: choice.idInDb ?? '',
            choiceIndex: i,
            nextSectionOrder: choice.nextSectionOrder,
          }
        : undefined
    )
    .filter(isNotNullOrUndefined)

  return {
    nextSectionInformation,
  }
}

const mapChoicesToIncludeNextSectionInformation = (
  choices: ChoiceInForm[],
  nextSectionInformations: NextSectionInformation[]
): ChoiceInForm[] => {
  if (!nextSectionInformations.length) {
    return choices
  }
  return choices.map((choice) => {
    const sectionOrderByChoiceId = nextSectionInformations.find(
      (info) => info.choiceId === choice.idInDb
    )?.nextSectionOrder
    return sectionOrderByChoiceId
      ? { ...choice, nextSectionOrder: sectionOrderByChoiceId }
      : choice
  })
}

export const mapSurveySchemaQuestionsToSections = (
  surveySchemaQuestions: SurveySchemaQuestion[]
): Section[] => {
  let questionOrderInSurvey = 0
  return Object.values(groupBy(surveySchemaQuestions, 'section')).map(
    (group: SurveySchemaQuestion[]) => {
      return {
        order: group[0].section,
        name: group[0].sectionName ?? '',
        questions: group.map((question: SurveySchemaQuestion) => {
          const { surveyQuestion } = question
          questionOrderInSurvey += 1
          return {
            section: question.section,
            orderInSection: question.orderInSection,
            orderInSurvey: questionOrderInSurvey,
            sectionName: question.sectionName,
            isRequired: question.required,
            isNew: false,
            idForRender: Math.random().toString(16).slice(2),
            isJumpToNextSectionAvailable:
              !!question.data?.nextSectionInformation.length,
            id: surveyQuestion.id,
            title: surveyQuestion.title,
            description: surveyQuestion.description || '',
            type: surveyQuestion.type,
            surveySchemaId: surveyQuestion.surveySchemaId ?? '',
            isSurveySpecific: !!surveyQuestion.surveySchemaId,
            isGlobal: surveyQuestion.isGlobal,
            data: surveyQuestion.data,
            choices: mapChoicesToIncludeNextSectionInformation(
              getDefaultValues(surveyQuestion.mcSurveyQuestionChoices, {
                shouldAddDoubleEmptyField: true,
              }) as ChoiceInForm[],
              question.data?.nextSectionInformation ?? []
            ),
            aspects: getDefaultValues(
              surveyQuestion.sclSurveyQuestionAspects
            ) as AspectInForm[],
          }
        }),
      }
    }
  )
}

export const mapSectionsToCreateSurveyQuestionToSurveySchemaInputs = (
  sections: Section[]
): SurveyQuestionToSurveySchemaInput[] => {
  const questions = sections
    .map((section) => [
      ...section.questions.map((q) => ({
        ...q,
        sectionOrder: section.order,
        sectionName: section.name,
      })),
    ])
    .flat()
  return questions.map((question) => ({
    surveyQuestionId: question.id,
    orderInSection: question.orderInSection,
    sectionName: question.sectionName,
    required: question.isRequired,
    section: question.sectionOrder,
    data: mapNextSectionInfosToSurveyQuestion2SurveySchemaDataInput(
      question.choices ?? []
    ),
    questionData: {
      title: question.title,
      description: question.description,
      type: question.type,
      surveySchemaId: question.surveySchemaId,
      isSurveySpecific: question.isSurveySpecific,
      data: getQuestionData(question),
      mcSurveyQuestionChoices: getValuesToSave(question.choices),
      sclSurveyQuestionAspects: getValuesToSave(question.aspects),
    },
  }))
}

export const mapSurveyStatus = (
  surveyStatus: SurveySchemaStatusEnum
): StatusEnum => {
  switch (surveyStatus) {
    case SurveySchemaStatusEnum.Active:
      return StatusEnum.Active
    case SurveySchemaStatusEnum.InActive:
      return StatusEnum.Inactive
    case SurveySchemaStatusEnum.Archived:
      return StatusEnum.Deleted
    default:
      return StatusEnum.Inactive
  }
}

export const mapFillOutStateFilter = (
  statusesFilter: Partial<Record<SurveyDoneStatusEnum, boolean>>
) =>
  Object.entries(statusesFilter ?? {})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, isChecked]) => isChecked)
    .map(([status]) => status as SurveyDoneStatusEnum)
