import { Autocomplete, TextField } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BoxWithLabel } from '../../../../../../common/components/BoxWithLabel'
import { useDoctorsForSendSurvey } from '../../../../../../common/hooks/useDoctorsForSendSurvey'
import { professionChore } from '../../../../../../models/graphqlTypes'
import { DoctorProfile } from './sendSurvey.types'
import { useUserProfile } from '../../../../../../common/hooks/useUserProfile'
import { useDebounce, useTranslation } from '../../../../../../common/hooks'
import { RECIPIENT_LIST_LIMIT } from './sendSurvey.constants'

export const SendSurveyToDoctor: React.FC = () => {
  const { t } = useTranslation()
  const profile = useUserProfile()

  const [searchInput, setSearchInput] = React.useState('')
  const nameFilter = useDebounce(searchInput, 500)

  const { doctors } = useDoctorsForSendSurvey({
    variables: {
      paginationInput: { limit: RECIPIENT_LIST_LIMIT, offset: 0 },
      filterInput: { name: nameFilter },
    },
  })

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  const selectedDoctors: DoctorProfile[] = watch('doctors')
  const patientOptions = selectedDoctors
    ?.flatMap((doctor: DoctorProfile) => doctor.ownPatients)
    .map((patient) => ({ ...patient.patient }))
    // only show patients that are assigned to the user and the other doctor
    .filter((patient) =>
      patient.doctors?.some((doctor) => doctor.id === profile?.id)
    )

  return (
    <>
      <BoxWithLabel label={t('survey:sendToDoctorLabel')}>
        <Controller
          control={control}
          name="doctors"
          rules={{
            validate: (value) =>
              value?.length < 1
                ? (t('messages:warnings.required') as string)
                : true,
          }}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Autocomplete
              multiple
              options={doctors}
              disableClearable
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(item) => {
                const professions = item.professions
                  .map((p: professionChore) => p.name)
                  .join(', ')
                const hasProfessions = item.professions.length > 0

                return `${item.lastName} ${item.firstName} ${hasProfessions ? `(${professions})` : ''}`
              }}
              value={value || []}
              onChange={(_, value) => {
                onChange([...value])
                setValue('aboutPatient', undefined)
              }}
              onInputChange={(_, value) => setSearchInput(value)}
              filterOptions={(x) => x}
              onBlur={onBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.doctors}
                  variant="outlined"
                  name={name}
                  helperText={errors.doctors?.message?.toString()}
                />
              )}
            />
          )}
        />
      </BoxWithLabel>

      <BoxWithLabel label={t('survey:sendToDoctorDescriptionForPatient')}>
        <Controller
          control={control}
          name="aboutPatient"
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Autocomplete
              options={patientOptions}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(patient) =>
                `${patient.lastName} ${patient.firstName} (${dayjs(
                  patient.birthDate ?? ''
                ).format('YYYY-MM-DD')}) - TAJ: ${patient.tajNumber}`
              }
              value={value ?? null}
              onChange={(_, value) => onChange(value)}
              onBlur={onBlur}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" name={name} />
              )}
            />
          )}
        />
      </BoxWithLabel>
    </>
  )
}
