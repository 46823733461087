import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ScrollablePanel } from '../../../../../common/components/ScrollablePanel'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { GetAggregatedSurveyResult } from './GetAggregatedSurveyResult'
import { GetSurveyResultByPatient } from './GetSurveyResultByPatient'
import { SurveyResultHeader } from './SurveyResultHeader'
import { SurveyResultQuestions } from './SurveyResultQuestions'
import {
  SurveyResultHeaderForm,
  SurveySchemaById,
} from '../../types/surveyStore.types'
import { FormProvider, useForm } from 'react-hook-form'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { FillOutState } from './FillOutState'
import { useSurveyPaths } from './useSurveyPaths'

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}))

interface Props {
  surveySchema: SurveySchemaById
}

export const SurveyResult: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const {
    surveyListPath,
    surveyResultQuestionsPath,
    surveyResultFillOutsPath,
    surveyResultAggregatedPath,
    surveyResultPatientsPath,
  } = useSurveyPaths()

  const formMethods = useForm<SurveyResultHeaderForm>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
      statusesFilter: {},
    },
  })

  return (
    <FormProvider {...formMethods}>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        overflow="hidden"
        sx={{ backgroundColor: '#EFEEF3' }}
      >
        <Box px={{ xs: 1, md: 2 }} pt={0.5} pb={1}>
          <Button
            variant="text"
            startIcon={<KeyboardBackspaceIcon />}
            sx={{ mb: 1.5 }}
            onClick={() => history.push(surveyListPath)}
            classes={{ textPrimary: classes.textPrimary }}
          >
            {t('survey:result.backToSurveyList')}
          </Button>
          <SurveyResultHeader surveySchema={surveySchema} />
        </Box>
        <Box overflow="hidden" px={2}>
          <ScrollablePanel withoutPadding>
            <Switch>
              <Route path={surveyResultQuestionsPath}>
                <SurveyResultQuestions
                  surveySchema={surveySchema}
                  isPreview={true}
                />
              </Route>
              <Route path={surveyResultFillOutsPath}>
                <FillOutState surveySchema={surveySchema} />
              </Route>
              <Route path={surveyResultAggregatedPath}>
                <GetAggregatedSurveyResult surveySchema={surveySchema} />
              </Route>
              <Route path={surveyResultPatientsPath}>
                <GetSurveyResultByPatient surveySchema={surveySchema} />
              </Route>
            </Switch>
          </ScrollablePanel>
        </Box>
      </Box>
    </FormProvider>
  )
}
