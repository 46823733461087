import { Autocomplete, TextField } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BoxWithLabel } from '../../../../../../common/components/BoxWithLabel'
import { useUserProfile } from '../../../../../../common/hooks/useUserProfile'
import { useTranslation } from '../../../../../../common/hooks'
import { useDoctorOwnAssistants } from '../../../../DoctorAssistants/hooks/useDoctorOwnAssistants'
import { usePatientsList } from '../../../../../../common/hooks/usePatientsList'
import { RECIPIENT_LIST_LIMIT } from './sendSurvey.constants'

export const SendSurveyToAssistant: React.FC = () => {
  const { t } = useTranslation()
  const profile = useUserProfile()

  const [assistantSearchInput, setAssistantSearchInput] = React.useState('')
  const [patientSearchInput, setPatientSearchInput] = React.useState('')

  const { assistants } = useDoctorOwnAssistants({
    searchTerm: assistantSearchInput,
  })

  const { data: patientOptions } = usePatientsList({
    variables: {
      doctorIds: [profile?.id ?? ''],
      filterData: {
        nameOrTAJ: patientSearchInput,
      },
      paginationInput: {
        offset: 0,
        limit: RECIPIENT_LIST_LIMIT,
      },
    },
  })

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  return (
    <>
      <BoxWithLabel label={t('survey:sendToAssistantLabel')}>
        <Controller
          control={control}
          name="assistants"
          rules={{
            validate: (value) =>
              value?.length < 1
                ? (t('messages:warnings.required') as string)
                : true,
          }}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Autocomplete
              multiple
              options={assistants}
              disableClearable
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(item) => `${item.name}`}
              value={value || []}
              onChange={(_, value) => {
                onChange([...value])
                setValue('aboutPatient', undefined)
              }}
              onInputChange={(_, value) => setAssistantSearchInput(value)}
              filterOptions={(x) => x}
              onBlur={onBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.doctors}
                  variant="outlined"
                  name={name}
                  helperText={errors.doctors?.message?.toString()}
                />
              )}
            />
          )}
        />
      </BoxWithLabel>

      <BoxWithLabel label={t('survey:sendToDoctorDescriptionForPatient')}>
        <Controller
          control={control}
          name="aboutPatient"
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Autocomplete
              options={patientOptions}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(patient) =>
                `${patient.lastName} ${patient.firstName} (${dayjs(
                  patient.birthDate ?? ''
                ).format('YYYY-MM-DD')}) - TAJ: ${patient.tajNumber}`
              }
              onInputChange={(_, value) => setPatientSearchInput(value)}
              value={value ?? null}
              onChange={(_, value) => onChange(value)}
              onBlur={onBlur}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" name={name} />
              )}
            />
          )}
        />
      </BoxWithLabel>
    </>
  )
}
