import React from 'react'
import { useTranslation } from '../../hooks'
import { Button } from '@mui/material'

export const ResetOneFilterButton: React.FC<
  React.PropsWithChildren<{ onClick: () => void }>
> = ({ onClick }) => {
  const { t } = useTranslation('patients')

  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      sx={{ marginLeft: 'auto', paddingX: '4px' }}
    >
      {t('filters.resetOne')}
    </Button>
  )
}
