import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../hooks'
import { hasActiveFilter } from '../../../utils/hasActiveFilter'
import ErrorIcon from '@mui/icons-material/Error'

interface Props {
  filterInput: object
  isFilterPanelOpen: boolean
  handleFilterOpen: () => void
}

export const OpenFilterPanelButton: React.FC<
  React.PropsWithChildren<Props>
> = ({ filterInput, isFilterPanelOpen, handleFilterOpen }) => {
  const { t } = useTranslation()

  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={handleFilterOpen}
      sx={{ paddingX: '16px' }}
    >
      {t('patients:detailedSearch')}
      {hasActiveFilter(filterInput) && !isFilterPanelOpen && (
        <ErrorIcon color="warning" sx={{ marginLeft: '4px' }} />
      )}
    </Button>
  )
}
