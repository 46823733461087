import { Stack, Typography } from '@mui/material'
import React from 'react'
import { SurveyStatusEnum } from '../../../../../models/graphqlTypes'
import dayjs from 'dayjs'
import { PatientSurvey } from '../../../../patient/PatientEvents/types/patientEvents.types'
import { useTranslation } from '../../../../../common/hooks'

interface Props {
  survey: PatientSurvey
}

export const SurveyStatus: React.FC<React.PropsWithChildren<Props>> = ({
  survey,
}) => {
  const { t } = useTranslation('survey')
  const { status, fillableUntil, finishedAt } = survey

  const hasExpired = dayjs(fillableUntil).isBefore(new Date())

  const FillableUntil = () => (
    <Typography color={hasExpired ? 'error' : ''} variant="subtitle1">
      {t('deadline', {
        date: dayjs(fillableUntil).format('YYYY. MMMM DD.'),
      })}
    </Typography>
  )

  if (hasExpired && status !== SurveyStatusEnum.Finished) {
    return (
      <Stack direction="column">
        <Typography variant="subtitle2" color={'error'}>
          {t('status.expired')}
        </Typography>
        {fillableUntil && <FillableUntil />}
      </Stack>
    )
  }

  switch (status) {
    case SurveyStatusEnum.Untouched:
    case SurveyStatusEnum.InProgress:
      return (
        <Stack direction="column">
          <Typography variant="subtitle2">{t('status.fillable')}</Typography>
          {fillableUntil && <FillableUntil />}
        </Stack>
      )

    case SurveyStatusEnum.Finished:
      return (
        <Stack>
          <Typography variant="subtitle2" mr={1} color="success.dark">
            {t('status.filled')}
          </Typography>
          <Typography variant="subtitle1" color="success.dark">
            {t('result.detail.fillOutDate')}:{' '}
            {dayjs(finishedAt).format('YYYY. MMMM DD.')}
          </Typography>
        </Stack>
      )
  }
}
