import React, { useCallback, useEffect } from 'react'
import { SurveySchemaById } from '../../types/surveyStore.types'
import { useSurveysBySchemaId } from '../../hooks/useSurveysBySchemaId'
import { PAGE_SIZE } from '../../../../../config'
import {
  BetmenList,
  BetmenListBody,
  BetmenListHeader,
  BetmenListHeaderCell,
  BetmenListItemCard,
  BetmenListItemCardCell,
} from '../../../../../common/components'
import { SurveyActionButton } from '../../../DoctorPatients/components/PatientSurveys/SurveyActionButton'
import { SurveyStatus } from '../../../DoctorPatients/components/PatientSurveys/SurveyStatus'
import { useFormContext } from 'react-hook-form'
import { mapFillOutStateFilter } from '../../utils/survey.mapper'
import { useTranslation } from '../../../../../common/hooks'
import { RecipientDetails } from '../../../DoctorPatients/components/PatientSurveys/RecipientDetail'

interface Props {
  surveySchema: SurveySchemaById
}

export const FillOutState: React.FC<Props> = ({ surveySchema }) => {
  const { t } = useTranslation()

  const { watch } = useFormContext()
  const statusesFilter = watch('statusesFilter')
  const selectedStatuses = mapFillOutStateFilter(statusesFilter)

  const {
    surveys,
    loading,
    hasMoreSurveys,
    fetchMoreSurveys,
    fetchingMore,
    refetch,
  } = useSurveysBySchemaId({
    variables: {
      filterData: { schemaId: surveySchema.id, statuses: selectedStatuses },
      paginationInput: { limit: PAGE_SIZE, offset: 0 },
    },
  })

  const onSurveyEnter = useCallback(() => {
    const offset = surveys.length
    fetchMoreSurveys({
      variables: {
        filterData: { schemaId: surveySchema.id },
        paginationInput: { limit: PAGE_SIZE, offset },
      },
    })
  }, [surveys.length, fetchMoreSurveys, surveySchema.id])

  useEffect(() => {
    refetch()
  }, [statusesFilter, refetch])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      <BetmenList
        dataSource={surveys}
        loading={loading}
        hasMoreItem={hasMoreSurveys}
        fetchMore={onSurveyEnter}
        fetchMoreLoading={fetchingMore}
        gridColumnSizes={[5, 4, 3]}
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell>
              {t('patients:survey.header.recipient')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('patients:survey.header.sender')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('patients:survey.header.status')}
            </BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {surveys.map((survey) => (
            <BetmenListItemCard key={survey.id}>
              <BetmenListItemCardCell>
                <RecipientDetails survey={survey} />
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {t('common:formattedNameFull', {
                  title: survey.doctor?.title,
                  firstName: survey.doctor?.firstName,
                  lastName: survey.doctor?.lastName,
                })}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <SurveyStatus survey={survey} />
                <SurveyActionButton survey={survey} />
              </BetmenListItemCardCell>
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>
    </div>
  )
}
