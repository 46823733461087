import { useQuery } from '@apollo/client'
import { GET_DOCTOR_OWN_ASSISTANTS } from '../operations/doctorOwnAssistantsOperations'
import { getDoctorOwnAssistants } from '../../../../models/graphqlTypes'
import {
  AssistantTableRowData,
  DoctorOwnAssistant,
} from '../types/doctorAssistants.types'
import { useMemo } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'

type Variables = {
  searchTerm?: string | null
}

type Return = {
  assistants: AssistantTableRowData[]
  loading: boolean
  refetch: () => void
}
export const useDoctorOwnAssistants = ({ searchTerm }: Variables): Return => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery<getDoctorOwnAssistants>(
    GET_DOCTOR_OWN_ASSISTANTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  )

  const assistantsData = data?.getDoctorOwnAssistants ?? []

  const assistants: AssistantTableRowData[] = assistantsData
    .filter(
      (doctorOwnAssistant: DoctorOwnAssistant) =>
        !doctorOwnAssistant.closedAt && !!doctorOwnAssistant.assistant?.user
    )
    .map((doctorOwnAssistant) => {
      const assistantProfile = doctorOwnAssistant.assistant

      return {
        id: assistantProfile.id,
        userId: assistantProfile.user.id,
        name: t('common:formattedNameFull', assistantProfile),
        email: assistantProfile.user?.email,
        registrationNumber: assistantProfile.registrationNumber,
      }
    })

  const filteredAssistants = useMemo(() => {
    if (!searchTerm) {
      return assistants
    }
    return assistants.filter(
      (assistant) =>
        assistant.name.includes(searchTerm) ||
        assistant.email.includes(searchTerm) ||
        assistant.registrationNumber.includes(searchTerm)
    )
  }, [assistants, searchTerm])

  return {
    loading,
    assistants: filteredAssistants,
    refetch,
  }
}
