import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'

import {
  getDoctorPatientSurveys,
  getDoctorPatientSurveysVariables,
} from '../../../../models/graphqlTypes'
import { GET_DOCTOR_PATIENT_SURVEYS } from '../../../../operations/doctorPatientOperations'
import { DoctorPatientSurvey } from '../types/surveys.types'

type Options = QueryHookOptions<
  getDoctorPatientSurveys,
  getDoctorPatientSurveysVariables
>

type ReturnData = {
  loading: boolean
  patientSurveys: DoctorPatientSurvey[]
  totalSurveys: number
  hasMoreSurveys: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSurveys: (options: Options) => void
}

export const useDoctorPatientSurveysData = (
  options: Options = {}
): ReturnData => {
  const {
    data: surveyData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getDoctorPatientSurveys, getDoctorPatientSurveysVariables>(
    GET_DOCTOR_PATIENT_SURVEYS,
    {
      ...options,
    }
  )

  const totalSurveys = surveyData?.getSurveysForPatient.total || 0
  const patientSurveys: DoctorPatientSurvey[] =
    surveyData?.getSurveysForPatient.surveys || []
  const hasMoreSurveys = patientSurveys.length < totalSurveys

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSurveys: fetchMore,
    refetch,
    patientSurveys,
    totalSurveys,
    hasMoreSurveys,
  }
}
